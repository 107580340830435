import { Grid } from '@mui/material'
import { CustomTabPanel } from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import React, { useCallback, useEffect, useState } from 'react'
import { ActiveFsFilters } from '../../../../../../redux/filters/filtersTypes'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks'
import { fetchDrillDownPromoInformations } from '../../../../../../redux/fsProducts/fsProductsSlice'
import Loading from '../../../../../../components/features/screens/LoadingScreen'
import { SubTitle } from '../../../../../../components/ui/Text'
import { Units } from '../../../../../../redux/global/globalTypes'
import { DataCartDrillDownPromoInfo } from './DataCardDrillDownPromoInfo'
import { round2digit } from './PromoInformationsTab'

export const PromoInformationsTabTGM = ({
    row,
    value,
}: {
    row: any
    value: number
}) => {
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const [isLoading, setLoading] = useState(true)

    const _fetchDrillDownPromoInformations = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownPromoInformations(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchData = async () => {
            await _fetchDrillDownPromoInformations(filters)
        }

        fetchData().then(() => setLoading(false))
    }, [])
    const { drillDownPromoInformations } = useAppSelector(
        (state) => state.fsProducts
    )

    return (
        <CustomTabPanel value={value} index={0}>
            {isLoading && <Loading text={false} height="54vh" />}
            {drillDownPromoInformations && !isLoading && (
                <Grid container alignItems="flex-start" flexDirection="column">
                    <SubTitle>Sell-out</SubTitle>
                    <Grid container flexDirection="row" wrap="nowrap">
                        <DataCartDrillDownPromoInfo
                            title="Volume de ventes total"
                            value={Math.round(
                                drillDownPromoInformations.sellOutKPI.volume
                            )}
                            unit={Units.kg}
                            difference={
                                drillDownPromoInformations.sellOutKPI.volume -
                                drillDownPromoInformations.sellOutKPI
                                    .volumeLastYear
                            }
                            notRoundable={false}
                        />
                        <DataCartDrillDownPromoInfo
                            title="VMM"
                            value={Math.round(
                                drillDownPromoInformations.sellOutKPI.vmm
                            )}
                            unit={Units.kg}
                            difference={
                                drillDownPromoInformations.sellOutKPI.vmm -
                                drillDownPromoInformations.sellOutKPI
                                    .vmmLastYear
                            }
                            notRoundable={false}
                        />
                    </Grid>
                    <Grid container flexDirection="row">
                        <Grid item mt="2vh" mr="2vw">
                            <SubTitle>Sell-in</SubTitle>
                            <DataCartDrillDownPromoInfo
                                title="Prix moyen net"
                                value={round2digit(
                                    drillDownPromoInformations.sellInKPI
                                        .netAveragePrice
                                )}
                                unit={Units['€/kg']}
                                difference={
                                    drillDownPromoInformations.sellInKPI
                                        .netAveragePrice -
                                    drillDownPromoInformations.sellInKPI
                                        .netAveragePriceLastYear
                                }
                                notRoundable
                            />
                        </Grid>
                        <Grid item mt="2vh">
                            <SubTitle>Promo</SubTitle>
                            <DataCartDrillDownPromoInfo
                                title="€ investis/kg"
                                value={round2digit(
                                    drillDownPromoInformations.promoKPI
                                        .investmentPerVolume
                                )}
                                unit={Units['€/kg']}
                                difference={
                                    drillDownPromoInformations.promoKPI
                                        .investmentPerVolume -
                                    drillDownPromoInformations.promoKPI
                                        .investmentPerVolumeLastYear
                                }
                                notRoundable={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </CustomTabPanel>
    )
}
