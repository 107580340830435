import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { ActiveFsFilters, ActiveRetailFilters } from '../filters/filtersTypes'
import { AppThunk } from '../store'
import {
    Env,
    EnvName,
    EnvStatus,
    EnvType,
    FsCrossDashboard,
    FsDashboard,
    RetailDashboard,
} from '../user/userTypes'
import { GlobalState, KPIsTypes, Units } from './globalTypes'
import {
    getActiveFilters,
    getURLForFilteredDashboard,
    isDashboardFiltered,
} from './utils'

const initialState: GlobalState = {
    KPIs: null,
    env: {
        name: EnvName.MYDISTRIB,
        type: EnvType.SETTING,
        status: EnvStatus.INACTIVATED,
    },
    fsDashboardData: null,
    fsCrossDashboardData: null,
    retailDashboardData: null,
    period: null,
    isLoading: false,
    error: null,
    unit: Units.kg,
    isFiltered: false,
}

const GlobalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        initGlobalInfos: (state, action: PayloadAction<GlobalState>) => {
            state = action.payload
        },
        setGlobalKPIs: (state, action: PayloadAction<KPIsTypes>) => {
            state.KPIs = action.payload
        },
        resetGlobalKPIs: (state) => {
            state.KPIs = null
        },
        setGlobalEnv: (state, action: PayloadAction<Env>) => {
            state.env = action.payload
        },
        resetDashboard: (state) => {
            state.fsDashboardData = null
            state.retailDashboardData = null
        },
        setFsDashboard: (state, action: PayloadAction<FsDashboard>) => {
            state.fsDashboardData = action.payload
        },
        setFsCrossDashboard: (
            state,
            action: PayloadAction<FsCrossDashboard>
        ) => {
            state.fsCrossDashboardData = action.payload
        },
        setRetailDashboard: (state, action: PayloadAction<RetailDashboard>) => {
            state.retailDashboardData = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        setUnit: (state, action: PayloadAction<Units>) => {
            state.unit = action.payload
        },
        setIsFiltered: (state, action: PayloadAction<boolean>) => {
            state.isFiltered = action.payload
        },
    },
})

export const {
    initGlobalInfos,
    setGlobalEnv,
    resetDashboard,
    setFsDashboard,
    setRetailDashboard,
    setGlobalKPIs,
    resetGlobalKPIs,
    setLoading,
    setError,
    setUnit,
    setIsFiltered,
    setFsCrossDashboard,
} = GlobalSlice.actions
export default GlobalSlice.reducer

export const fetchFilteredDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const company = getState().user.user.brand
            const envType = getState().global.env.type
            const unit = getState().global.unit
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }
            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const url = getURLForFilteredDashboard(envType, distributor, unit)
            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }

            dispatch(setLoading(true))

            try {
                const dashboard = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (envType === EnvType.FOOD_SERVICE_CROSS) {
                    dispatch(
                        setFsCrossDashboard({
                            productsChart:
                                dashboard.productsChart.formatedProductsChart,
                            legendChart: dashboard.productsChart.legend,
                            productsTopFlop: dashboard.productsTopFlop,
                            KPIs: dashboard.kpis,
                            clients: dashboard.clients,
                            distributorsList: dashboard.distributorsList,
                        })
                    )
                    dispatch(setGlobalKPIs(dashboard.kpis))
                }

                if (envType === EnvType.FOOD_SERVICE) {
                    if (distributor === EnvName.SODEXO || (distributor === EnvName.METRO && company === "Lactalis")) {
                        dispatch(setUnit(Units.uvc))
                    } else {
                        dispatch(setUnit(Units.kg))
                    }
                    dispatch(
                        setFsDashboard({
                            products: dashboard.products,
                            productsChart:
                                dashboard.productsChart.formatedProductsChart,
                            legendChart: dashboard.productsChart.legend,
                            KPIs: dashboard.kpis,
                            clients: dashboard.clients,
                            results: dashboard.results,
                            warehouses: dashboard.warehouses
                                ? dashboard.warehouses
                                : [],
                            categories: dashboard.categories
                                ? dashboard.categories
                                : [],
                        })
                    )
                    dispatch(setGlobalKPIs(dashboard.kpis))
                } else if (envType === EnvType.RETAIl) {
                    dispatch(
                        setRetailDashboard({
                            kpis: dashboard.kpis[0],
                            products: dashboard.products,
                            productsChart: dashboard.productsChart,
                            productsSplit: dashboard.productsSplit[0],
                            stores: dashboard.stores,
                            geoStores: dashboard.geoStores,
                        })
                    )
                }
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }
