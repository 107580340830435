import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Tooltip,
} from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import LockIcon from '@mui/icons-material/Lock'
import { BLACK, DARK_BLUE, LIGHT_BLUE } from '../../ui/Colors'
import { PrimaryText } from '../../ui/Text'

export interface NavigationData {
    id: number
    label: string
    link: string
    icon?: JSX.Element
    locked?: boolean
    lockedTooltip?: string
    customAction?: () => void
}

interface NavigationItemProps {
    item: NavigationData
    isActive?: boolean
}

interface OwnProps {
    data: NavigationData[]
}

const ItemActive = {
    background: LIGHT_BLUE,
    borderRadius: '5px',
    '& svg': {
        color: BLACK,
    },
}

const ItemInactive = {
    background: 'transparent',
    borderRadius: '5px',
    '& svg': {
        color: BLACK,
    },
}

const LockedItem = ({ item }: NavigationItemProps) => {
    return (
        <Tooltip title={item.lockedTooltip}>
            <ListItem sx={ItemInactive} disablePadding>
                <ListItemButton disabled sx={{ padding: '0.8vh 1vw' }}>
                    <ListItemIcon
                        sx={{
                            minWidth: { xs: '40px', md: '3vw' },
                            '& svg': {
                                width: { xs: '22px', md: '2.5vw' },
                                height: { xs: '22px', md: '2.5vh' },
                            },
                        }}
                    >
                        <LockIcon color="disabled" />
                    </ListItemIcon>
                    <PrimaryText color={DARK_BLUE} noWrap>
                        {item.label}
                    </PrimaryText>
                </ListItemButton>
            </ListItem>
        </Tooltip>
    )
}

const NavigationItem = ({ item, isActive }: NavigationItemProps) => {
    return (
        <Link
            onClick={(e) => {
                if (item.customAction) {
                    e.preventDefault()
                    item.customAction()
                }
            }}
            to={item.link}
            key={item.id}
            style={{ textDecoration: 'none', color: BLACK }}
        >
            <ListItem sx={isActive ? ItemActive : ItemInactive} disablePadding>
                <ListItemButton sx={{ padding: '0.8vh 0.6vw' }}>
                    <ListItemIcon
                        sx={{
                            minWidth: { xs: '40px', md: '3vw' },
                            '& svg': {
                                width: { xs: '22px', md: '2.5vw' },
                                height: { xs: '22px', md: '2.5vh' },
                            },
                        }}
                    >
                        {item.icon}
                    </ListItemIcon>
                    <PrimaryText color={BLACK} noWrap>
                        {item.label}
                    </PrimaryText>
                </ListItemButton>
            </ListItem>
        </Link>
    )
}

const Navigation = ({ data }: OwnProps) => {
    const { pathname } = useLocation()

    return (
        <List
            id="navbar"
            sx={{
                padding: 0,
                width: 'fill-available',
            }}
        >
            {data.map((item) => {
                if (item.locked) {
                    return <LockedItem key={item.id} item={item} />
                } else {
                    const isActive = pathname.replace('%20', ' ') === item.link

                    return (
                        <NavigationItem
                            key={item.id}
                            item={item}
                            isActive={isActive}
                        />
                    )
                }
            })}
        </List>
    )
}

export default Navigation
