import React from "react"
import { BLACK } from "../../../ui/Colors";
import { PrimaryText } from "../../../ui/Text";
import { Step } from "react-joyride";


export const dashboardStep = [
    {
        content: <PrimaryText color={BLACK}><span style={{ fontWeight: "bold" }}>Vous êtes maintenant sur le tableau de bord Carrefour !</span><br />Vous retrouvez ici toutes les données nécessaires pour suivre vos ventes.</PrimaryText>,
        locale: { skip: <strong aria-label="skip">Passer</strong>, next: "Suivant", last: "Suivant", back: "Retour" },
        hideBackButton: true,
        disableBeacon: true,
        disableOverlayClose: true,
        placement: 'center',
        target: 'body',
        data: {},
    }, {
        content: <PrimaryText color={BLACK}>Vous pouvez filtrer toutes vos données, par référence, par point de vente et par circuit de distribution.<br />Les filtres sont cumulables !</PrimaryText>,
        locale: { skip: <strong aria-label="skip">Passer</strong>, next: "Suivant", last: "Suivant", back: "Retour" },
        disableOverlayClose: true,
        placement: 'bottom',
        target: "#filters-container",
        data: {},
    },
    {
        content: <PrimaryText color={BLACK}>Vous pouvez également sélectionner votre période d'analyse.</PrimaryText>,
        locale: { skip: <strong aria-label="skip">Passer</strong>, next: "Suivant", last: "Suivant", back: "Retour" },
        disableOverlayClose: true,
        placement: 'bottom',
        target: '#period',
        data: {},
    }, {
        content: <PrimaryText color={BLACK}>Utilisez la barre de navigation pour accéder aux pages Points de vente et Références, pour analyser vos données au travers d'un axe plus spécifique.</PrimaryText>,
        locale: { next: "Suivant", last: "Suivant", back: "Retour" },
        disableOverlayClose: true,
        placement: 'right',
        target: '#navbar',
        data: {
            next: "/retail/Carrefour/magasins"
        },
    }] as Step[]

export const dashboardEndSteps = [{
    content: <PrimaryText color={BLACK}><span style={{ fontWeight: 'bold' }}>Vous êtes maintenant prêt à découvrir par vous même la plateforme Karyon !</span><br />N'hésitez pas à nous écrire à l'adresse suivante en cas de besoin : hind@karyonfood.com</PrimaryText>,
    locale: { next: "Suivant", last: "Terminer", back: "Retour" },
    disableOverlayClose: true,
    placement: 'center',
    target: "body",
    data: {},
}] as Step[]