import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Box } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { string, object } from 'yup'
import {
    inputsCompanyForAdmin,
    inputsCompanyForUser,
    inputsProfil,
    inputsdata,
    menuForAdmin,
    menuForUser,
} from './settingsPageProfil'
import { InputsProfilPage } from './InputsProfilPage'
import { MenuProfil } from './MenuProfil'
import { handleScroll } from './handleScroll'
import { InvitationUserForm } from './InvitationUserForm'
import { PrimaryText, SubTitle } from '../../../../components/ui/Text'
import Avatar from '../../../../components/features/Avatar'
import { GREEN, LIGHT_GREY } from '../../../../components/ui/Colors'
import { Separator } from '../../../../components/ui/Separator'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import ErrorMessage from '../../../../components/ui/ErrorMessage'
import {
    CompanyData,
    KaryonPlans,
} from '../../../../redux/companies/companiesTypes'
import {
    fetchCompany,
    fetchUpdateCompany,
} from '../../../../redux/companies/companiesSlice'
import {
    Env,
    User,
    UsersRole,
    UsersStatus,
} from '../../../../redux/user/userTypes'
import { PrimaryButton } from '../../../../components/ui/Button'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchUpdateUser } from '../../../../redux/user/userSlice'
import { hasOthers } from '../../../public/authentication/signUp/steps/SignUpRetail'
import {
    fsDistributor,
    retailDistributor,
} from '../../../../helpers/distributorsList'

export interface EditProfilValues {
    firstname: string
    lastname: string
    email: string
    phoneNumber: string
    job: string
}

export interface EditCompanyValues {
    distributerRetail: string[]
    distributerFS: string[]
    dataRetail: string[]
    dataFS: string[]
    hasOthersRetail?: boolean
    hasOthersFS?: boolean
    companyName: string
    members: {
        firstname: string
        lastname: string
        email: string
        status: UsersStatus
    }[]
    env: Env[]
}

export type EditValues = EditCompanyValues &
    EditProfilValues & {
        distributerRetailStartingList: string[]
        distributerFSStartingList: string[]
        buttonDisabled: boolean
    }
interface ValidationStatus {
    status: 'submit' | 'error' | null
    msg: string
}

export interface FormProps {
    user: User
    company: CompanyData
    onSubmit: (values: EditValues) => void
    submitMsg: ValidationStatus
}

const PureUserUpdateForm = (
    props: FormikProps<EditValues> & FormProps
): JSX.Element => {
    useEffect(() => {
        handleScroll(props.user.role)
        window.addEventListener('scroll', () => handleScroll(props.user.role))
        return () => {
            window.removeEventListener('scroll', () =>
                handleScroll(props.user.role)
            )
        }
    }, [])

    useEffect(() => {
        props.setFieldValue('buttonDisabled', true)
    }, [props.submitCount])

    if (props.dirty && props.submitCount < 1) {
        props.values.buttonDisabled = false
    }

    const inputsToDisplay =
        props.user.role === UsersRole.ADMIN
            ? [{ ...inputsProfil, id: 1 }, inputsCompanyForAdmin, inputsdata]
            : [{ ...inputsProfil, id: 0 }, inputsCompanyForUser]

    return (
        <Form>
            <Grid container direction="row">
                <Grid container alignItems="center" direction="column" mt="5vh">
                    <Grid>
                        {inputsToDisplay.map((section) => (
                            <Grid
                                key={section.id}
                                container
                                direction="column"
                                mb="5vh"
                            >
                                <Separator />
                                <SubTitle
                                    bold
                                    id={`${section.id}`}
                                    mt="4vh"
                                    mb="4vh"
                                >
                                    {section.title}
                                </SubTitle>
                                {section.inputs.map((input) => (
                                    <Grid
                                        sx={{
                                            '& .MuiFormHelperText-root': {
                                                fontSize: '1.6vh',
                                            },
                                        }}
                                    >
                                        <InputsProfilPage
                                            key={input.id}
                                            name={input.name}
                                            type={input.type}
                                            label={input.label}
                                            list={
                                                input.list ? input.list : null
                                            }
                                            disabled={input.disabled}
                                            {...props}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid>
                        {props.submitMsg &&
                            (props.submitMsg.status === 'submit' ? (
                                <PrimaryText color={GREEN}>
                                    {props.submitMsg.msg}
                                </PrimaryText>
                            ) : (
                                <ErrorMessage msg={props.submitMsg.msg} />
                            ))}
                    </Grid>
                    <Grid>
                        <Box mt="2vh">
                            <PrimaryButton
                                disabled={props.values.buttonDisabled}
                                type="submit"
                                style={{ marginBottom: '2vh' }}
                            >
                                Enregistrer
                            </PrimaryButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
}

const ControlledUpdateUserForm = withFormik<FormProps, EditValues>({
    mapPropsToValues: ({ user, company }) => ({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneNumber: user.phoneNumber ? user.phoneNumber : '',
        job: user.job,
        distributerRetail: company.retail ? company.retail.distributer : [],
        distributerFS: company.fs ? company.fs.distributer : [],
        dataRetail: company.retail ? company.retail.data : [],
        dataFS: company.fs ? company.fs.data : [],
        distributerFSStartingList: company.fs ? company.fs.distributer : [],
        distributerRetailStartingList: company.retail
            ? company.retail.distributer
            : [],
        buttonDisabled: true,
        companyName: company.name ? company.name : '',
        members: company.members ? company.members : null,
        env: company.env ? company.env : [],
    }),

    validationSchema: () => {
        return object().shape({
            firstname: string().required('Le champ Prénom est obligatoire'),
            lastname: string().required('Le champ Nom est obligatoire'),
            job: string().required('Le champ Fonction est obligatoire'),
            companyName: string().required(
                'Le champ Marque Commerciale est obligatoire'
            ),
        })
    },
    enableReinitialize: true,
    handleSubmit: (
        values: EditValues,
        bag: FormikBag<FormProps, EditValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureUserUpdateForm)

export const ProfilPage = () => {
    const user = useAppSelector((state) => state.user.user)
    const company = useAppSelector((state) => state.companies)
    const dispatch = useAppDispatch()
    const _updateUser = useCallback(
        async (user: Partial<User> & EditProfilValues) =>
            await dispatch(fetchUpdateUser(user)),
        [dispatch]
    )
    const _updateCompany = useCallback(
        async (company: Partial<CompanyData> & EditCompanyValues) =>
            await dispatch(fetchUpdateCompany(company)),
        [dispatch]
    )
    const _fetchCompany = useCallback(
        async () => await dispatch(fetchCompany()),
        [dispatch]
    )

    const initialStatus = {
        status: null,
        msg: '',
    }
    const [submitMsg, setSubmitMsg] = useState<ValidationStatus>(initialStatus)

    useEffect(() => {
        if (user.companyId) {
            _fetchCompany()
        }
    }, [user.companyId])

    useEffect(() => {
        if (submitMsg.status === 'submit') {
            const interval = setInterval(() => {
                setSubmitMsg(initialStatus)
            }, 3500)
            return () => {
                clearInterval(interval)
            }
        }
    }, [submitMsg.status])

    const handleSubmit = async (values: EditValues) => {
        delete values.email
        delete values.buttonDisabled

        const hasOthersRetail = hasOthers(
            values.distributerRetail,
            retailDistributor
        )
        const hasOthersFS = hasOthers(values.distributerFS, fsDistributor)
        let userModified = null
        let companyModified = null

        if (user.role === UsersRole.ADMIN) {
            companyModified = await _updateCompany({
                distributerRetail: values.distributerRetail,
                distributerFS: values.distributerFS,
                dataRetail: values.dataRetail,
                dataFS: values.dataFS,
                companyName: values.companyName,
                members: values.members,
                env: values.env,
                hasOthersRetail,
                hasOthersFS,
            })
            userModified = await _updateUser({
                firstname: values.firstname,
                lastname: values.lastname,
                job: values.job,
                email: values.email,
                phoneNumber: values.phoneNumber,
            })
        } else {
            userModified = await _updateUser({
                firstname: values.firstname,
                lastname: values.lastname,
                job: values.job,
                email: values.email,
                phoneNumber: values.phoneNumber,
            })
        }

        if (userModified || companyModified) {
            setSubmitMsg({
                status: 'submit',
                msg: 'Vos modifications ont bien été enregistrées !',
            })
        } else {
            setSubmitMsg({
                status: 'error',
                msg: 'Aucune modification à enregistrer',
            })
        }
    }

    const menuData = user.role === UsersRole.ADMIN ? menuForAdmin : menuForUser

    return (
        <PrivatePage title="Profil">
            <Grid
                container
                direction="column"
                sx={{ background: LIGHT_GREY }}
                p="1vw"
            >
                <MenuProfil data={menuData} />
                <Paper>
                    <Grid container direction="column" alignItems="center">
                        {user.firstname.length && (
                            <Box mt={-5}>
                                <Avatar name={user.firstname[0]} large />
                            </Box>
                        )}
                        {user.role === UsersRole.ADMIN && (
                            <InvitationUserForm />
                        )}
                        <ControlledUpdateUserForm
                            submitMsg={submitMsg}
                            onSubmit={handleSubmit}
                            user={user}
                            company={company.company}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </PrivatePage>
    )
}
