import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid, Typography } from '@mui/material'
import { BLACK, LIGHT_GREY, REGULAR_MOCK } from '../ui/Colors'
import NumberWithSpace from '../ui/NumberWithSpace'
import { PrimaryText, SecondaryText, SubTitle } from '../ui/Text'
import Tendency from '../ui/Tendency'
import { useNavigate } from 'react-router-dom'

export interface KpiCard {
    name: string
    number: number
    units?: string
    difference?: number
    route?: string
}

export function MockedPrimaryCard() {
    return (
        <Card
            sx={{
                flex: 1,
                mr: 2,
                '&:last-child': {
                    mr: 0,
                },
                height: 100,
                boxShadow: 'none',
            }}
        >
            <CardContent>
                <Typography
                    variant="body1"
                    gutterBottom
                    style={{
                        color: 'transparent',
                        background: REGULAR_MOCK,
                        fontSize: '2vmin',
                    }}
                >
                    Nom
                </Typography>
                <Grid container alignItems="baseline">
                    <Typography
                        variant="h5"
                        style={{
                            color: 'transparent',
                            background: REGULAR_MOCK,
                            fontSize: '2vmin',
                        }}
                    >
                        Number
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default function PrimaryKpiCard({
    name,
    number,
    units,
    difference,
    route = null,
}: KpiCard) {
    const navigate = useNavigate()

    return (
        <Card
            sx={{
                flex: 1,
                padding: '0.5vw',
                height: {
                    xl: 'auto',
                },
                '&:hover': { cursor: route && 'pointer', background: LIGHT_GREY },
                '&:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            onClick={() => {
                if (route) navigate(route)
            }}
        >
            <CardContent
                sx={{
                    padding: '0px',
                    ':last-child': { padding: '0px' },
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Grid width="100%">
                    <PrimaryText color={BLACK} gutterBottom>
                        {name}
                    </PrimaryText>
                    <Grid
                        container
                        alignItems="flex-bottom"
                        justifyContent="space-between"
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Grid container alignItems="baseline">
                                <SubTitle color={BLACK}>
                                    <NumberWithSpace number={number} />
                                </SubTitle>
                                <SecondaryText color={BLACK} ml="0.5vw">
                                    {units}
                                </SecondaryText>
                            </Grid>
                        </Grid>
                        {difference != undefined && (
                            <Grid item>
                                <Grid
                                    container
                                    height="100%"
                                    alignItems="flex-end"
                                >
                                    <Tendency
                                        kpi
                                        difference={difference}
                                        unit={units}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
