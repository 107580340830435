import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import React, { useCallback, useState, useEffect } from 'react'
import { object, string } from 'yup'
import { Grid } from '@mui/material'
import { PrimaryText, SubTitle } from '../../../../components/ui/Text'
import { DARK_BLUE, GREEN } from '../../../../components/ui/Colors'
import { RadioButtonsGroup } from '../../../../components/ui/RadioButtonsGroup'
import { EnhancedField, TextInput } from '../../../../components/ui/Inputs'
import ErrorMessage from '../../../../components/ui/ErrorMessage'
import { PrimaryButton } from '../../../../components/ui/Button'
import {
    CodeErrorUserInvitation,
    UserInvitation,
    UsersRole,
} from '../../../../redux/user/userTypes'
import { useAppDispatch } from '../../../../redux/hooks'
import { inviteUser } from '../../../../redux/user/userSlice'

interface EditValues {
    role: UsersRole
    email: string
}
interface FormProps {
    roles: {
        label: string
        value: UsersRole
    }[]
    onSubmit: (values: EditValues) => void
    submitMsg: ValidationStatus
}

interface ValidationStatus {
    status: 'submit' | 'error' | null
    msg: string
}

const PureInvitationUserForm = (
    props: FormikProps<EditValues> & FormProps
): JSX.Element => {
    useEffect(() => {
        if (props.submitMsg.status) {
            props.resetForm()
        }
    }, [props.submitMsg])

    return (
        <Form>
            <Grid container alignContent="center" width="40vw">
                <Grid container direction="column" width="100%" mt="2vh" id="0">
                    <SubTitle bold mt="4vh" mb="4vh">
                        Invitez des membres à rejoindre votre espace !
                    </SubTitle>
                    <PrimaryText color={DARK_BLUE}>
                        Une fois l’invitation acceptée, ils auront accès à
                        toutes les données de la plateforme.
                    </PrimaryText>
                    <Grid container direction="column" mt="2vh">
                        <Grid>
                            <RadioButtonsGroup
                                onChange={(event, value) =>
                                    props.setFieldValue('role', value)
                                }
                                values={props.roles}
                                label={`Rôle de l'utilisateur`}
                                defaultCheck={UsersRole.USER}
                            />
                        </Grid>
                        <Grid mt="2vh">
                            <PrimaryText color={DARK_BLUE} bold>
                                Renseignez l'email de la personne que vous
                                souhaitez inviter :
                            </PrimaryText>
                            <Grid container direction="row" alignItems="center">
                                <Grid width="20vw">
                                    <EnhancedField
                                        type="email"
                                        name="email"
                                        margin="normal"
                                        component={TextInput}
                                        placeholder="Email"
                                    />
                                </Grid>
                                <Grid ml="2vw">
                                    <PrimaryButton type="submit">
                                        Envoyer l'invitation
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {props.submitMsg &&
                (props.submitMsg.status === 'submit' ? (
                    <PrimaryText color={GREEN}>
                        {props.submitMsg.msg}
                    </PrimaryText>
                ) : (
                    <ErrorMessage msg={props.submitMsg.msg} />
                ))}
        </Form>
    )
}

const ControlledInvitationUserForm = withFormik<FormProps, EditValues>({
    mapPropsToValues: () => ({
        email: '',
        role: UsersRole.USER,
    }),

    validationSchema: () => {
        return object().shape({
            email: string().email().required('Le champ Email est obligatoire'),
            role: string().required('Le champ Rôle est obligatoire'),
        })
    },
    enableReinitialize: true,
    handleSubmit: (
        values: EditValues,
        bag: FormikBag<FormProps, EditValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureInvitationUserForm)

export const InvitationUserForm = () => {
    const dispatch = useAppDispatch()
    const _inviteUser = useCallback(
        (data: UserInvitation) => dispatch(inviteUser(data)),
        [dispatch]
    )

    const initialStatus = {
        status: null,
        msg: '',
    }
    const [submitMsg, setSubmitMsg] = useState<ValidationStatus>(initialStatus)

    const roles = [
        { label: 'Utilisateur', value: UsersRole.USER },
        {
            label: 'Administrateur : peut gérer le compte entreprise et inviter d’autres membres à l’espace KaryonFood.',
            value: UsersRole.ADMIN,
        },
    ]

    const handleSubmit = async (values: UserInvitation) => {
        const sendInvitation = await _inviteUser(values).then(
            (response) => response
        )

        if (sendInvitation.result['success']) {
            setSubmitMsg({
                status: 'submit',
                msg: `L'invitation a bien été envoyée`,
            })
        } else if (sendInvitation.result['error']) {
            let textError = ''

            if (
                sendInvitation.result['error'] ===
                CodeErrorUserInvitation.USER_EXIST
            ) {
                textError = "L'utilisateur existe déjà"
            } else if (
                sendInvitation.result['error'] ===
                CodeErrorUserInvitation.USER_DEACTIVATED
            ) {
                textError = "L'utilisateur a été désactivé"
            } else {
                textError = 'Une erreur est survenue'
            }

            setSubmitMsg({
                status: 'error',
                msg: textError,
            })
        }
    }

    return (
        <ControlledInvitationUserForm
            submitMsg={submitMsg}
            onSubmit={handleSubmit}
            roles={roles}
        />
    )
}
