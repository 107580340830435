import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import { Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PublicNavigation from './components/features/navigation/routing/PublicNavigation'
import PrivateNavigation from './components/features/navigation/routing/PrivateNavigation'
import { useAppDispatch, useAppSelector } from './redux/hooks'
import { fetchUser, logUserConnection } from './redux/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { ENVIRONMENTS, setHeaders } from './api/config'
import Loading from './components/features/screens/LoadingScreen'
import { Box } from '@mui/material'
import RetailProductTour from './components/features/productTour/RetailProductTour'

const UPDATE_DATE = new Date('2023-06-01')

const App = () => {
    const theme = createTheme({
        typography: {
            body1: {
                color: 'black',
                opacity: '87%',
            },
            fontFamily: ['Cabin', 'sans-serif'].join(','),
        },
    })
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)
    const { env } = useAppSelector((state) => state.user.user)
    const [session, setSession] = useState(null)
    const dispatch = useAppDispatch()
    const _fetchUser = useCallback(() => dispatch(fetchUser()), [dispatch])
    const _logUserConnection = useCallback(
        () => dispatch(logUserConnection()),
        [dispatch]
    )

    useEffect(() => {
        const handleFetchUser = async () => {
            await _fetchUser()
            setLoading(false)
        }

        setSession(null)
        supabase.auth.onAuthStateChange((_event, session) => {
            if (_event === 'PASSWORD_RECOVERY') {
                setSession(session)
                setLoading(false)
                navigate('/parametres/setnewpassword')
            } else if (_event === 'SIGNED_OUT') {
                setSession(null)
                setLoading(false)
                navigate('/')
            } else if (session) {
                setSession(session)
                setHeaders(session.access_token)
                handleFetchUser()
                if (!window.sessionStorage.getItem('session_On')) {
                    sessionStorage.setItem('session_On', 'true')
                    _logUserConnection()
                }
                const userUpdateDate = new Date(session.user.updated_at)
                if (userUpdateDate < UPDATE_DATE) {
                    localStorage.setItem('first_connection_after_update', 'yes')
                }
            } else {
                setLoading(false)
            }
        })
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Box height="100%">
                {isLoading ? (
                    <Loading />
                ) : (
                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <Navigation
                                    authenticated={session!! && !!env.length}
                                />
                            }
                        />
                    </Routes>
                )}
            </Box>
        </ThemeProvider>
    )
}

const Navigation = ({ authenticated }: { authenticated: boolean }) => {
    const userEmail = useAppSelector((state) => state.user.user.email)
    const hasProductTour = process.env.REACT_APP_ENV === ENVIRONMENTS.ALPHA && !localStorage.getItem('productTour') /* && !userEmail.includes('karyonfood') */

    return authenticated ? <>{hasProductTour && <RetailProductTour />}<PrivateNavigation /></> : <PublicNavigation />
}

export default App
