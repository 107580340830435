import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    type ChartOptions,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import PieLabels, { labelsConfig } from './PieLabels'
import { Grid } from '@mui/material'
import { useAppSelector } from '../../../../../../redux/hooks'
import { sortFiveArrSameWay } from '../../../../../../helpers/sort'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = () => {
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const chartRef = React.useRef()
    const { productsSplit } = useAppSelector(
        (state) => state.global.retailDashboardData
    )

    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const labelIndex = model.dataIndex
                        const total = model.dataset.data.reduce(
                            (acc, curr) => acc + curr,
                            0
                        )

                        return ` ${
                            sortedProducts.second[labelIndex]
                        } : ${Math.round((model.parsed / total) * 100)}%`
                    },
                    footer: (model: any) => {
                        const sells = Math.round(model[0].parsed)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const evolution = Math.round(
                            sortedProducts.third[model[0].dataIndex]
                        )

                        const stores = Math.round(
                            sortedProducts.fourth[model[0].dataIndex]
                        )

                        const storesEvolution = Math.round(
                            sortedProducts.fifth[model[0].dataIndex]
                        )

                        return `Volume vendu : ${sells} uvc\n Evolution Volume : ${evolution} %
                        \n Nombre de magasins : ${stores} \n Evolution magasins : ${storesEvolution} %`
                    },
                },
                padding: { top: 20, right: 10, left: 10 },
            },
        },
    }

    const sortedProducts = sortFiveArrSameWay(
        productsSplit.values,
        productsSplit.labels,
        productsSplit.evolution,
        productsSplit.stores,
        productsSplit.storesEvolution
    )

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            sx={{ height: '100%' }}
        >
            <Grid
                container
                flex={1}
                alignItems="center"
                justifyContent="center"
                height="58%"
                paddingTop="2.5vh"
            >
                <Pie
                    ref={chartRef}
                    style={{ width: '100%' }}
                    options={options as ChartOptions}
                    data={{
                        datasets: [
                            {
                                data: sortedProducts.base,
                                backgroundColor:
                                    labelsConfig[0].backgroundColor,
                                borderWidth: 0,
                                borderColor: 'black',
                            },
                        ],
                    }}
                />
            </Grid>
            <Grid
                item
                flex={2}
                mt="2vh"
                sx={{
                    maxHeight: '90%',
                }}
            >
                {isLabels ? (
                    <PieLabels
                        chart={chartRef.current}
                        labels={sortedProducts.second}
                        data={sortedProducts.base}
                    />
                ) : (
                    'loading'
                )}
            </Grid>
        </Grid>
    )
}

export default PieChart
