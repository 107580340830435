import {
    Checkbox,
    MenuItem,
    OutlinedInput,
    Select,
    Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../../ui/Button'
import { BLACK, MEDIUM_GREY, PRIMARY, WHITE } from '../../../ui/Colors'
import { RenderedFilter } from '../FilterSelect'
import SelectSearch from './SelectSearch'
import NoResultsFilter from './NoResultsFilter'
import { FilterComponent, checkboxCSS } from './FilterComponent'
import {
    calculateLengthOfChildren,
    filterData,
    toggleSelectAll,
} from './FiltersFunction'
import NoDataFilter from './NoDataFilter'
import { useAppSelector } from '../../../../redux/hooks'
import { SecondaryText } from '../../../ui/Text'
import { EnvName } from '../../../../redux/user/userTypes'
import { MenuType } from '../utils'
import { PagesName } from '../../navigation/types/pages'

export interface FiltersData {
    id: string
    name: string
    level: number
    children?: {
        id: string
        name: string
        level: number
        children?: { id: string; name: string; level: number }[]
    }[]
}

interface OwnProps {
    name: string
    icon: JSX.Element
    placeholder: string
    type: MenuType
    data: FiltersData[]
    setFieldValue: (field: string, value: string[]) => void
    filtersValues: string[]
    depth: number
    disabled?: boolean
    page?: PagesName
    submitFilters: () => void
    isDialogFilter: boolean
}

const isDisabled = (page: PagesName, envName: EnvName, type: MenuType) => {
    if (
        page === PagesName.WAREHOUSES &&
        envName === EnvName.TRANSGOURMET &&
        type !== MenuType.CATEGORIES
    ) {
        return true
    } else if (
        page === PagesName.CLIENTS &&
        envName === EnvName.TRANSGOURMET &&
        type === MenuType.CATEGORIES
    ) {
        return true
    } else if (page === PagesName.CLIENTS && envName === EnvName.METRO) {
        return true
    }
    return false
}
export const borderFilter = `1px solid ${MEDIUM_GREY} !important`
export const borderFilterActive = '2px solid #0075FF !important'
export const borderFilterSelected = `1px solid ${BLACK} !important`

const MainFilter = ({
    icon,
    placeholder,
    type,
    data,
    setFieldValue,
    filtersValues,
    depth,
    page,
    isDialogFilter,
    submitFilters
}: OwnProps) => {
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [list, setList] = useState(data)
    const { env } = useAppSelector((state) => state.global)
    const [selectBorder, setSelectBorder] = useState('')
    const isFilterDisabled = isDisabled(page, env.name, type)

    const handleClose = () => {
        !isDialogFilter && submitFilters()
        setOpen(false)
        if (filtersValues.length > 0) {
            setSelectBorder(borderFilterSelected)
        } else {
            setSelectBorder(borderFilter)
        }
    }
    const handleOpen = () => {
        setSelectBorder(borderFilterActive)
        setOpen(true)
    }

    const filteredResultsAfterSearch = filterData(data, search, depth)
    useEffect(() => {
        setList(filteredResultsAfterSearch)
    }, [search, data])

    useEffect(() => {
        if (filtersValues.length > 0) {
            setSelectBorder(borderFilterSelected)
        } else {
            setSelectBorder('')
        }
    }, [filtersValues])

    const lengthOfChildren = calculateLengthOfChildren(data, depth)

    return (
        <Tooltip
            title={
                isFilterDisabled
                    ? 'Ce filtre est inactif sur cette page. Les données du distributeur ne permettent pas de croiser les informations'
                    : ''
            }
        >
            <Select
                size="small"
                sx={{
                    pt: 0,
                    flex: 1,
                    overflow: 'hidden',
                    background: WHITE,
                    height: '3.5vh',
                    mr: 2,
                    '.MuiSelect-iconOutlined': {
                        width: '1.5vw',
                        height: '3.5vh',
                        top: 0,
                    },
                    '&:last-child': { mr: 0 },
                    '.MuiOutlinedInput-notchedOutline': {
                        border: selectBorder,
                    },
                }}
                disabled={isFilterDisabled}
                multiple
                displayEmpty
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                value={[]}
                input={<OutlinedInput />}
                renderValue={() => {
                    return (
                        <RenderedFilter
                            data={data}
                            lengthOfChildren={lengthOfChildren}
                            selected={filtersValues}
                            menu={data}
                            icon={icon}
                            placeholder={placeholder}
                            depth={depth}
                        />
                    )
                }}
                MenuProps={{
                    sx: {
                        maxHeight: '90vh',
                        '& .MuiPaper-elevation': { overflow: 'inherit' },
                        '& .MuiMenu-list': { maxHeight: '74vh' },
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
            >
                <MenuItem
                    sx={{
                        height: '6vh',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: `WHITE !important`,
                        zIndex: 2,
                        opacity: 1,
                    }}
                >
                    <SelectSearch search={search} setSearch={setSearch} />
                </MenuItem>
                <MenuItem
                    sx={{
                        position: 'sticky',
                        top: '6vh',
                        backgroundColor: `${WHITE} !important`,
                        zIndex: 2,
                        opacity: 1,
                        borderBottom: '0.2vh solid rgba(0, 0, 0, 0.12)',
                        mb: '0.5vh',
                        pl: 0,
                    }}
                    onClick={() => {
                        toggleSelectAll(
                            list,
                            type,
                            filtersValues,
                            setFieldValue,
                            depth
                        )
                    }}
                >
                    <Checkbox
                        sx={checkboxCSS}
                        checked={filtersValues.length === lengthOfChildren}
                    />
                    <SecondaryText color={BLACK}>
                        Tout sélectionner
                    </SecondaryText>
                </MenuItem>
                {env.status === 'onload-init' ? (
                    <NoDataFilter />
                ) : list.length > 0 ? (
                    <FilterComponent
                        filters={filtersValues}
                        data={list}
                        setFieldValue={setFieldValue}
                        type={type}
                        search={search}
                        depth={depth}
                        activeDepth={1}
                    />
                ) : (
                    <NoResultsFilter />
                )}

                {isDialogFilter ? (<PrimaryButton
                    sx={{
                        width: '100%',
                        backgroundColor: `${WHITE} !important`,
                        borderWidth: '0.2vh',
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 2,
                        opacity: 1,
                        fontSize: { xs: '16px', md: '1.5vmin' },
                        borderTop: '0.2vh solid rgba(0, 0, 0, 0.12)',
                    }}
                    onClick={handleClose}
                >
                    Fermer
                </PrimaryButton>) : (<PrimaryButton
                    sx={{
                        width: '100%',
                        backgroundColor: `${PRIMARY} !important`,
                        color: "white !important",
                        borderWidth: '0.2vh',
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 2,
                        opacity: 1,
                        fontSize: { xs: '16px', md: '1.5vmin' },
                        borderTop: '0.2vh solid rgba(0, 0, 0, 0.12)',
                    }}
                    onClick={submitFilters}
                >
                    Filtrer
                </PrimaryButton>)}

            </Select>
        </Tooltip>
    )
}

export default MainFilter
