import React from 'react'
import Period, { DatesFormValues } from './Period'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { formateDateToString } from '../../utils/formateDateToString'
import {
    setActiveFsCrossFilters,
    setActiveFsFilters,
    setActiveFsViewFilters,
    setActiveRetailFilters,
    setActiveRetailViewFilters,
} from '../../../../redux/filters/filtersSlice'
import { EnvType } from '../../../../redux/user/userTypes'

export const SwitchPeriod = ({ onViewPage = false }: { onViewPage?: boolean }) => {
    const { env } = useAppSelector((state) => state.global)

    switch (env.type) {
        case EnvType.FOOD_SERVICE:
            return <FsPeriod onViewPage={onViewPage} />
        case EnvType.FOOD_SERVICE_CROSS:
            return <FsCrossPeriod onViewPage={onViewPage} />
        case EnvType.RETAIl:
            return <RetailPeriod onViewPage={onViewPage} />
    }
}

const FsCrossPeriod = ({ onViewPage }: { onViewPage: boolean }) => {
    const dispatch = useAppDispatch()

    const { activeFsCrossFilters, fsCrossFilters } = useAppSelector(
        (state) => state.filters
    )

    const handleSubmitFsView = (values: DatesFormValues) => {
        const formatedDate = formateDateToString(
            values.startDate,
            values.endDate
        )

        dispatch(
            setActiveFsCrossFilters({
                startDate: formatedDate.start,
                endDate: formatedDate.end,
                distributors: activeFsCrossFilters.distributors,
                products: activeFsCrossFilters.products,
                regions: activeFsCrossFilters.regions,
                clients: activeFsCrossFilters.clients,
            })
        )
    }

    if (!fsCrossFilters || !fsCrossFilters.startDate) {
        return null
    }

    return (
        <Period
            handleSubmit={handleSubmitFsView}
            activeValues={activeFsCrossFilters}
            initialValues={fsCrossFilters}
        />
    )
}

const FsPeriod = ({ onViewPage }: { onViewPage: boolean }) => {
    const dispatch = useAppDispatch()

    if (onViewPage) {
        const { activeFsViewFilters, fsViewFilters } = useAppSelector(
            (state) => state.filters
        )
        const handleSubmitFsView = (values: DatesFormValues) => {
            const formatedDate = formateDateToString(
                values.startDate,
                values.endDate
            )

            dispatch(
                setActiveFsViewFilters({
                    startDate: formatedDate.start,
                    endDate: formatedDate.end,
                    products: activeFsViewFilters.products,
                    clients: activeFsViewFilters.clients,
                    regions: activeFsViewFilters.regions,
                    categories: activeFsViewFilters.categories,
                })
            )
        }
        if (!fsViewFilters || !fsViewFilters.startDate) {
            return null
        }

        return (
            <Period
                handleSubmit={handleSubmitFsView}
                activeValues={activeFsViewFilters}
                initialValues={fsViewFilters}
            />
        )
    } else {
        const { activeFsFilters, fsFilters } = useAppSelector(
            (state) => state.filters
        )

        const handleSubmitFs = (values: DatesFormValues) => {
            const formatedDate = formateDateToString(
                values.startDate,
                values.endDate
            )

            dispatch(
                setActiveFsFilters({
                    startDate: formatedDate.start,
                    endDate: formatedDate.end,
                    products: activeFsFilters.products,
                    clients: activeFsFilters.clients,
                    regions: activeFsFilters.regions,
                    categories: activeFsFilters.categories,
                })
            )
        }

        if (!fsFilters || !fsFilters.startDate) {
            return null
        }

        return (
            <Period
                handleSubmit={handleSubmitFs}
                activeValues={activeFsFilters}
                initialValues={fsFilters}
            />
        )
    }
}

const RetailPeriod = ({ onViewPage }: { onViewPage: boolean }) => {
    const dispatch = useAppDispatch()

    if (onViewPage) {
        const { activeRetailViewFilters, retailViewFilters } = useAppSelector(
            (state) => state.filters
        )
        const handleSubmitRetailView = (values: DatesFormValues) => {
            const formatedDate = formateDateToString(
                values.startDate,
                values.endDate
            )

            dispatch(
                setActiveRetailViewFilters({
                    startDate: formatedDate.start,
                    endDate: formatedDate.end,
                    stores: activeRetailViewFilters.stores,
                    products: activeRetailViewFilters.products,
                    distributionChannels:
                        activeRetailViewFilters.distributionChannels,
                })
            )
        }

        if (!retailViewFilters || !retailViewFilters.startDate) {
            return null
        }

        return (
            <Period
                handleSubmit={handleSubmitRetailView}
                activeValues={activeRetailViewFilters}
                initialValues={retailViewFilters}
            />
        )
    } else {
        const { activeRetailFilters, retailFilters } = useAppSelector(
            (state) => state.filters
        )

        const handleSubmitRetail = (values: DatesFormValues) => {
            const formatedDate = formateDateToString(
                values.startDate,
                values.endDate
            )

            dispatch(
                setActiveRetailFilters({
                    startDate: formatedDate.start,
                    endDate: formatedDate.end,
                    stores: activeRetailFilters.stores,
                    products: activeRetailFilters.products,
                    distributionChannels:
                        activeRetailFilters.distributionChannels,
                })
            )
        }

        if (!retailFilters || !retailFilters.startDate) {
            return null
        }

        return (
            <Period
                handleSubmit={handleSubmitRetail}
                activeValues={activeRetailFilters}
                initialValues={retailFilters}
            />
        )
    }
}
