import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { Env, EnvName, EnvStatus, EnvType } from '../../../../../redux/user/userTypes'
import { setGlobalEnv } from '../../../../../redux/global/globalSlice'
import { Navigate } from 'react-router-dom'
import { ENVIRONMENTS } from '../../../../../api/config'

export const validEnvStatus = [
    EnvStatus.ACTIVATED,
    EnvStatus.ON_LOADING,
    EnvStatus.OUTDATED,
    EnvStatus.ON_LOADING_INIT,
]

const ChannelRouter = () => {
    const { env: userEnv, email } = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    // TODO : Check in local storage if there is a past active env
    if (process.env.REACT_APP_ENV === ENVIRONMENTS.ALPHA && !localStorage.getItem('productTour') /* && !email.includes('karyonfood') */) {
        _setEnv({
            name: EnvName.SETTING,
            type: EnvType.SETTING,
            status: EnvStatus.ACTIVATED
        })

        return <Navigate to="/parametres/import-retail" />
    }

    // TODO : Add sort function to handle multiple env status list
    const activeEnv =
        userEnv.length &&
        userEnv.filter(
            ({ status, type }) =>
                validEnvStatus.includes(status) && type !== EnvType.SETTING
        )[0]

    // TODO : handle no env => Redirection to import or fallback page

    _setEnv(activeEnv)

    return <Navigate to={`/${activeEnv.type}/${activeEnv.name}`} />
}

export default ChannelRouter
