import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import PieChart from './pie/PieChart'
import { useAppSelector } from '../../../../../redux/hooks'
import { Units } from '../../../../../redux/global/globalTypes'

const RetailSegmentation = () => {
    const { env } = useAppSelector((state) => state.global)
    return (
        <ChartBlock
            title={`Répartition par circuit de distribution (${Units.uvc})`}
            link={`/retail/${env.name}/produits`}
        >
            <PieChart />
        </ChartBlock>
    )
}

export default RetailSegmentation
