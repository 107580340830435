export enum EnvType {
    RETAIl = 'retail',
    FOOD_SERVICE = 'food-service',
    SETTING = 'setting',
    FOOD_SERVICE_CROSS = 'food-service-cross',
    RETAIL_CROSS = 'retail-cross',
}

export enum EnvStatus {
    OUTDATED = 'outdated',
    ACTIVATED = 'activated',
    INACTIVATED = 'inactivated',
    ON_LOADING = 'onload',
    ON_LOADING_INIT = 'onload-init',
}

export enum EnvName {
    FOOD_SERVICE_CROSS = 'Foodservice',
    RETAIL_CROSS = 'Retail',
    FRANCE_FRAIS = 'France Frais',
    TRANSGOURMET = 'Transgourmet',
    MYDISTRIB = 'Mes Distributeurs',
    METRO = 'Metro',
    CARREFOUR = 'Carrefour',
    AUCHAN = 'Auchan',
    EPISAVEURS = 'EpiSaveurs',
    PASSIONFROID = 'PassionFroid',
    SODEXO = 'Sodexo',
    INTERMARCHE = 'Intermarché',
    SETTING = 'Paramètres',
    CERCLEVERT = "Cercle vert"
}

export enum ConnectorType {
    MANUAL = 'manual',
    AUTOMATED = 'automated',
}
export interface Env {
    name: EnvName
    type: EnvType
    status: EnvStatus
    connectorType?: ConnectorType
}

interface DistributerData {
    distributed: boolean
    distributer: string[]
    data: string[]
    hasOthers: boolean
}

export interface User {
    _id: string
    supabaseId?: string
    email: string
    firstname: string
    lastname: string
    job: string
    phoneNumber?: string
    brand: string
    retail: DistributerData
    fs: DistributerData
    env: Env[]
    companyId?: string
    role: UsersRole
    status: UsersStatus
    useCases: string[]
    modules: KaryonModules[]
}

export enum KaryonModules {
    PROMO = 'promotions',
    CROSS = 'cross-distributor',
}

export enum UsersRole {
    ADMIN = 'admin',
    USER = 'user',
}

export enum UsersStatus {
    PENDING = 'pending',
    ACTIVATED = 'activated',
    DEACTIVATED = 'deactivated',
    STANDALONE = 'non rattaché',
}
export interface userState {
    user: User | null
}

// DASHBOARD FS
export interface Products {
    _id: string
    clients: number
    divider: number
    differenceWeight: number
    oldVolume: number
    products: number
    vmm: number
    volume: number
}

export interface FsFFRKPIs {
    warehouses: number
    warehousesLastYear: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    vmmLastYear: number
}

export interface FsEpiSaveursKPIs {
    warehouses: number
    warehousesLastYear: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    vmmLastYear: number
    products: number
    productsLastYear: number
}

export interface FsCercleVertKPIs {
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
}

export interface FsMetroKPIs {
    warehouses: number
    warehousesLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    vmmLastYear: number
    products: number
    productsLastYear: number
}

export interface FsCrossKPIs {
    warehouses: number
    warehousesLastYear: number
    volume: number
    volumeLastYear: number
    VMM: number
    VMMLastYear: number
}

export interface FsSodexoKPIs {
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
}

export interface FsTGMKPIs {
    warehouses: number
    warehousesLastYear: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
}

interface Clients {
    labels: string[]
    data: number[]
    evolution: number[]
    evolutionClients: number[]
    dataLastYear: number[]
    clients: number[]
    clientsLastYear: number[]
    products: number[]
    differenceWeight: number[]
    differenceClients: number[]
    differenceProducts: number[]
    productsLastYear: number[]
    warehouses: number[]
    warehousesLastYear: number[]
    VMM: number[]
    VMMLastYear: number[]
    differenceVMM: number[]
}

interface Results {
    current: {
        labels: string[]
        data: number[]
    }
    old: {
        labels: string[]
        data: number[]
    }
}

interface Warehouses {
    _id: string
    lastYearSells: number
    sells: number
    evolYear: string
    currentDate: string
    lastYearDate: string
}

interface Distributors {
    id: string
    volume: number
    warehouses: number
    VMM: string
    percentage: number
}

export interface Categories {
    id: string
    volume: number
    volumeLastYear: number
    differenceWeight: number
}

export interface FsDashboard {
    products: Products[]
    productsChart: ProductsChart[]
    legendChart: string[]
    KPIs: FsFFRKPIs | FsTGMKPIs
    clients: Clients
    results: Results
    warehouses: Warehouses[]
    categories: Categories[]
}

export interface FsCrossDashboard {
    productsChart: ProductsChart[]
    legendChart: string[]
    clients: Clients
    productsTopFlop: any
    KPIs: FsCrossKPIs
    distributorsList: Distributors[]
}

export interface ProductsChart {
    name: string
    labels: string[]
    data: number[]
    evolution: number[]
}

// DASHBOARD RETAIL
interface RetailKpis {
    stores: number
    storesLastYear: number
    sells: number
    sellsLastYear: number
    products: number
    productsLastYear: number
    vmm: number
    vmmLastYear: number
}

export interface RetailProduct {
    _id: string
    stores: number
    evolution: number
    oldValue: number
    value: number
    vmm: number
    name: string
    difference: number
}

interface RetailProductsSplit {
    labels: string[]
    values: number[]
    evolution: number[]
    storesEvolution: number[]
    stores: number[]
}

interface Store {
    region: string
    stores: number
    values: number
}

interface GeoStore {
    store: string
    position: { lat: number; lng: number }
}

export interface RetailDashboard {
    kpis: RetailKpis
    products: RetailProduct[]
    productsChart: { name: string; labels: string[]; data: number[] }[]
    productsSplit: RetailProductsSplit
    stores: Store[]
    geoStores: GeoStore[]
}

// Invitations

export interface UserInviteResponse {
    result: { success: string } | { error: string }
}

export interface UserInvitation {
    role: UsersRole
    email: string
}

export enum CodeErrorUserInvitation {
    USER_EXIST = 'user_exist',
    USER_INVITED = 'user_invited',
    USER_DEACTIVATED = 'user_deactivated',
}

export interface UserInvitationValidityResponse {
    validity: boolean
    message: string
    email: string
}
