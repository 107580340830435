import React from "react"
import { BLACK } from "../../../ui/Colors";
import { PrimaryText, SubTitle } from "../../../ui/Text";
import { Step } from "react-joyride";

export const importSteps = [{
    content: <PrimaryText color={BLACK}><span style={{ fontWeight: "bold" }}>Bienvenue sur la plateforme Karyon !</span><br /> Vous allez être guidé par une présentation des principales fonctionnalités de la plateforme. <br /> Appuyez sur le bouton "Suivant" pour continuer.</PrimaryText>,
    locale: { skip: <strong aria-label="skip">Passer</strong>, next: "Suivant", last: "Suivant", back: "Retour" },
    disableOverlayClose: true,
    placement: 'center',
    target: 'body',
    data: {},
},
{
    content: <PrimaryText color={BLACK}>Vous pouvez vous déplacer dans les différents environnements à votre disposition.</PrimaryText>,
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
        options: {
            zIndex: 10000,
        },
    },
    target: "#env-selector",
    title: <SubTitle bold>Cliquez sur le bouton</SubTitle>,
    data: {},
},
{
    content: <PrimaryText color={BLACK}>Vous pouvez naviguer entre vos environnements distributeur par un simple clic.</PrimaryText>,
    locale: { next: "Suivant", last: "Suivant", back: "Retour" },
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    placement: 'right',
    styles: {
        options: {
            zIndex: 10000,
        },
    },
    target: "#Carrefour-distributor",
    data: {
        previous: "",
        next: '/retail/Carrefour/dashboard',
    },
}] as Step[]