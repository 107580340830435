import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import FsCrossDashboard from '../../../../../../pages/private/Foodservice/Cross/FsCrossDashboard'

export const FsCrossRouter = (modules: KaryonModules[]): routerType[] => {
    if (!modules.includes(KaryonModules.CROSS)) {
        return []
    }

    return [
        {
            path: '/dashboard',
            element: <FsCrossDashboard />,
            title: '',
        },
    ]
}
