import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAppSelector } from '../../../../../../redux/hooks'
import { monthsAsObject } from '../../../../../../helpers/months'
import { sortTwoArrSameWay } from '../../../../../../helpers/sort'
import { labels } from '../../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'
import ChartBlock from '../../../../../../components/features/ChartBlock'
import { Histogram } from '../../../../../../components/features/foodService/dashboard/histogram/Histogram'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const HistogramRetail = () => {
    const { productsChart } = useAppSelector(
        (state) => state.global.retailDashboardData
    )

    const { env } = useAppSelector((state) => state.global)

    const sortedOld = productsChart[1]
        ? sortTwoArrSameWay(
              productsChart[1].labels,
              productsChart[1].data,
              true
          )
        : null

    const sortedCurrent = sortTwoArrSameWay(
        productsChart[0].labels,
        productsChart[0].data,
        true
    )

    const contextedLabels = sortedCurrent.base.map((label) => {
        return monthsAsObject[label.slice(5, 7)]
    })

    const contextedLabelsForTooltip = {
        current: sortedCurrent.base.map((label) => {
            return `${monthsAsObject[label.slice(5, 7)]} ${label.slice(0, 4)}`
        }),
        old: sortedOld
            ? sortedOld.base.map((label) => {
                  return `${monthsAsObject[label.slice(5, 7)]} ${label.slice(
                      0,
                      4
                  )}`
              })
            : null,
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (model: any) => {
                        const dateToDisplay =
                            model[0].datasetIndex === 1
                                ? contextedLabelsForTooltip.current[
                                      model[0].dataIndex
                                  ]
                                : sortedOld &&
                                  contextedLabelsForTooltip.old[
                                      model[0].dataIndex
                                  ]

                        return dateToDisplay
                    },
                    label: (model: any) => {
                        const value = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return `${value} uvc`
                    },
                    footer: (model: any) => {
                        const difference =
                            Math.round(model[0].raw) -
                            Math.round(sortedOld.second[model[0].dataIndex])

                        const dataLastYear = Math.round(
                            sortedOld.second[model[0].dataIndex]
                        )

                        if (dataLastYear === 0) return 'Évolution : -'

                        return model[0].datasetIndex === 1
                            ? difference > 0
                                ? `Évolution : ↑ ${Math.round(
                                      (difference * 100) / dataLastYear
                                  )} %`
                                : `Évolution : ↓ ${Math.round(
                                      (difference * 100) / dataLastYear
                                  )} %`
                            : ''
                    },
                },
            },
        },
    }
    const data = {
        labels: contextedLabels,
        datasets: [
            ...(sortedOld
                ? [
                      {
                          data: sortedOld.second,
                          backgroundColor: labels[0].backgroundColor,
                          borderColor: labels[0].backgroundColor,
                      },
                  ]
                : []),
            {
                data: sortedCurrent.second,
                backgroundColor: labels[1].backgroundColor,
                borderColor: labels[1].backgroundColor,
            },
        ],
    }

    return (
        <ChartBlock
            title="Total des ventes par mois (UVC)"
            link={`/retail/${env.name}/produits`}
        >
            <Histogram data={data} options={options} height={'20vh'} />
        </ChartBlock>
    )
}
