import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ActiveFsExportFilters,
    ActiveRetailExportFilters,
    ExportData,
    ExportDataResponse,
    ExportSteps,
    ExportTypes,
    FsExportFilters,
    InitialState,
    ListExport,
    MultiOrMono,
    RetailExportFilters,
    UserFormatedExportData,
} from './exportsTypes'
import { EnvType } from '../user/userTypes'
import { AppThunk } from '../store'
import client from '../../api/config'
import { setFsFilters, setRetailFilters } from '../filters/filtersSlice'
import { formateDateToString } from '../../components/features/utils/formateDateToString'
import { ResponseExportRaw } from '../../components/features/export/getExportRawSupabase'

const inititalExportFsFilters: ActiveFsExportFilters = {
    startDate: '2022-01',
    endDate: '2022-12',
    products: [],
    clients: [],
    regions: [],
}

const inititalExportRetailFilters: ActiveRetailExportFilters = {
    startDate: '2022-01',
    endDate: '2022-12',
    products: [],
    regions: [],
}

const initialState: InitialState = {
    config: {
        steps: [
            ExportSteps.TypeOfExport,
            ExportSteps.EnvChoice,
            ExportSteps.FiltersFS,
            ExportSteps.FiltersRetail,
            ExportSteps.FiltersMulti,
            ExportSteps.reasonOfExport,
        ],
    },
    data: {
        type: ExportTypes.EXCEL,
        distributors: [],
        filters: {
            products: [],
            startDate: '2022-01',
            endDate: '2022-12',
            regions: [],
            clients: [],
            family: [],
            stores: [],
            warehouses: [],
        },
        reason: '',
        multiOrMono: MultiOrMono.MONO,
        environment: EnvType.FOOD_SERVICE,
    },
    retailExportFilters: null,
    fsExportFilters: null,
    activeFsExportFilters: inititalExportFsFilters,
    activeRetailExportFilters: inititalExportRetailFilters,
    listExport: null,
}

const exportsSlice = createSlice({
    name: 'export',
    initialState,
    reducers: {
        updateData: (
            state,
            action: PayloadAction<{ data: Partial<ExportData> }>
        ) => {
            state.data = { ...state.data, ...action.payload.data }
        },
        setFsExportFilters: (state, action: PayloadAction<FsExportFilters>) => {
            state.fsExportFilters = action.payload
        },
        setRetailExportFilters: (
            state,
            action: PayloadAction<RetailExportFilters>
        ) => {
            state.retailExportFilters = action.payload
        },
        setActiveFsExportFilters: (
            state,
            action: PayloadAction<ActiveFsExportFilters>
        ) => {
            state.activeFsExportFilters = action.payload
        },
        setActiveRetailExportFilters: (
            state,
            action: PayloadAction<ActiveRetailExportFilters>
        ) => {
            state.activeRetailExportFilters = action.payload
        },
        setListExport: (state, action: PayloadAction<ListExport>) => {
            state.listExport = action.payload
        },
    },
})
export const { setFsExportFilters, setRetailExportFilters, setListExport } =
    exportsSlice.actions

export const fetchFiltersExport =
    (distributors: string[], env: EnvType): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const filters = await client
                .get(
                    `/user/filtersExport?distributors=${distributors}&type=${env}`
                )
                .then((response) => {
                    return response.data[0] as
                        | FsExportFilters
                        | RetailExportFilters
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            if (env === EnvType.FOOD_SERVICE) {
                dispatch(
                    setFsFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        products: filters.products,
                        clients: filters.clients,
                        regions: filters.regions,
                    })
                )
            } else {
                dispatch(
                    setRetailFilters({
                        startDate: '2022-01',
                        endDate: '2022-12',
                        products: filters.products,
                    })
                )
            }
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchListExport =
    (distributor: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const exportsList = await client
                .get(`/exports/${distributor}`)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })
            const formatedList = exportsList.list.map(
                (file: { filename: string; period: string }) => {
                    return {
                        id: file.filename,
                        filename: file.filename,
                        period: file.period,
                    }
                }
            )
            dispatch(setListExport(formatedList))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const handleExportCreation =
    (data: ExportData): AppThunk<Promise<ExportDataResponse | null>> =>
    async (dispatch, getState) => {
        const userId = getState().user.user._id
        const userbrand = getState().user.user.brand
        const date = formateDateToString(data.startDate, data.endDate)

        const formatedExportData: UserFormatedExportData = {
            userId: userId,
            type: data.type,
            distributors: data.distributors,
            filters: {
                startDate: date.start,
                endDate: date.end,
                products: data.products,
                regions: data.regions ? data.regions : [],
                warehouses: data.warehouses ? data.warehouses : [],
                clients: data.clients ? data.clients : [],
                family: data.family ? data.family : [],
                stores: data.stores ? data.stores : [],
            },
            reason: data.reason,
            environment: data.environment,
            multiOrMono: data.multiOrMono,
        }
        try {
            const exportCreated = await client
                .post(`/exports`, formatedExportData)
                .then((response) => {
                    return response
                })

            return { exportedData: exportCreated.data, brand: userbrand }
        } catch (e) {
            console.log('ERROR : ', e)
            return { exportedData: null }
        }
    }

export const LogExportRawActivity =
    (data: ResponseExportRaw[], reason: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const exportsList = await client
                .post(`/activities/export/raw`, { data, reason })
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export default exportsSlice.reducer
