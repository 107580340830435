import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getClientsColumns,
    getFSRows,
} from './setupDrillDown'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownClients,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownType } from './ProductsDrillDownContent'

export const ProductsCercleVertDrillDownContent = ({
    row,
    isFirstVisit,
    value,
    handleChange,
    type,
}: DrillDownContentProps) => {
    const [isLoadingClients, setLoadingClients] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownClients } = useAppSelector(
        (state) => state.fsProducts
    )
    const clientsLength = drillDownClients ? drillDownClients.length : ''

    const _fetchDrillDownClients = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownClients(filters))
        },
        [dispatch]
    )
    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataClients = async () => {
            await _fetchDrillDownClients(filters)
        }
        fetchDataClients().then(() => setLoadingClients(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Utilisateurs finaux (${clientsLength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownClients && !isLoadingClients && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownClients)}
                        columns={getClientsColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.CLIENTSCERCLEVERT,
                            value: row.row.clientsSubSubType,
                            oldValue: row.row.clientsSubSubTypeLastYear,
                        })}
                    />
                )}
                {isLoadingClients && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs >
    )
}
