import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { ActiveRetailFilters } from '../filters/filtersTypes'
import { setLoading } from '../global/globalSlice'
import { AppThunk } from '../store'
import {
    DrillDownProducts,
    DrillDownStores,
    ProductItemRetail,
    Products,
    RetailProductsState,
    StoreItem,
    Stores,
} from './retailProductsTypes'

const initialState: RetailProductsState = {
    stores: null,
    products: null,
    drillDownProducts: null,
    drillDownStores: null,
}

const retailProductsSlice = createSlice({
    name: 'retail-products',
    initialState,
    reducers: {
        setStores: (state, action: PayloadAction<Stores>) => {
            state.stores = action.payload
        },
        setProducts: (state, action: PayloadAction<Products>) => {
            state.products = action.payload
        },
        setProductsRetail: (
            state,
            action: PayloadAction<DrillDownProducts>
        ) => {
            state.drillDownProducts = action.payload
        },
        setStoresDrillDownStores: (
            state,
            action: PayloadAction<DrillDownStores>
        ) => {
            state.drillDownStores = action.payload
        },
    },
})

export const {
    setStores,
    setProducts,
    setProductsRetail,
    setStoresDrillDownStores,
} = retailProductsSlice.actions
export default retailProductsSlice.reducer

export const fetchFilteredretailStores =
    (filters: ActiveRetailFilters): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        const distributor = getState().global.env.name
        dispatch(setLoading(true))
        try {
            const results = await client
                .post(`/retail/products/stores?distrib=${distributor}`, filters)
                .then((response) => {
                    return {
                        stores: {
                            list: response.data.list,
                        } as Stores,
                        period: response.data.period,
                    }
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })
            dispatch(setStores(results.stores))
            dispatch(setLoading(false))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchFilteredretailProducts =
    (filters: ActiveRetailFilters): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        const distributor = getState().global.env.name
        dispatch(setLoading(true))
        try {
            const results = await client
                .post(`/retail/products?distrib=${distributor}`, filters)
                .then((response) => {
                    return {
                        products: {
                            chart: response.data.chart,
                            list: response.data.list,
                        } as Products,
                        period: response.data.period,
                    }
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })
            dispatch(setProducts(results.products))
            dispatch(setLoading(false))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchDrillDownProducts =
    (filters: ActiveRetailFilters): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        const distributor = getState().global.env.name

        try {
            const result = await client
                .post(`/retail/products/detail?distrib=${distributor}`, filters)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            dispatch(setProductsRetail(result))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchDrillDownStores =
    (filters: ActiveRetailFilters): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        const distributor = getState().global.env.name

        try {
            const result = await client
                .post(
                    `/retail/products/stores/detail?distrib=${distributor}`,
                    filters
                )
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            dispatch(setStoresDrillDownStores(result))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }
