import React, { useState } from 'react'
import LineChartLabels from './LineChartLabels'
import { TreeItem } from '@mui/x-tree-view'

interface OwnProps {
    chart: any
    name: string
    nodeId: string
    products: string[]
    start: number
}
export const TreeComponent = ({
    chart,
    name,
    nodeId,
    products,
    start,
}: OwnProps) => {
    const [selected, setSelected] = useState<number>(0)

    return (
        <TreeItem
            nodeId={nodeId}
            label={`${name} (${products.length - selected}/${products.length})`}
            sx={{
                maxHeight: '100%',
                '& .MuiTreeItem-label': {
                    ml: 0.5,
                },
            }}
        >
            <LineChartLabels
                setSelected={setSelected}
                chart={chart}
                products={products}
                start={start}
            />
        </TreeItem>
    )
}
