import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material'
// import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useEffect, useState } from 'react'
import { DARK_GREY, GREY, LIGHT_GREY, PRIMARY, WHITE } from '../../ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
    type ViewConfig,
    ViewsSteps,
    ViewTypes,
} from '../../../redux/views/viewsTypes'
import ViewsNavigationItem, {
    type ViewNavigationData,
} from './ViewsNavigationItem'
import { useLocation } from 'react-router-dom'

// Icons
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import AddIcon from '@mui/icons-material/Add'
import { PrimaryText } from '../../ui/Text'
import { fetchViews, setupViews } from '../../../redux/views/viewsSlice'
import { EnvType } from '../../../redux/user/userTypes'
import LockIcon from '@mui/icons-material/Lock'


const AddViewButton = ({ env }: { env: EnvType }) => {
    const dispatch = useAppDispatch()
    const _setupViews = useCallback(
        (viewConfig: ViewConfig) =>
            dispatch(setupViews({ config: viewConfig })),
        [dispatch]
    )

    const handleClick = () => {
        if (env === EnvType.FOOD_SERVICE_CROSS) return null
        if (env === EnvType.FOOD_SERVICE) {
            _setupViews({
                steps: [ViewsSteps.FsSettings, ViewsSteps.FsFilters],
            })
        } else {
            _setupViews({
                steps: [ViewsSteps.RetailSettings, ViewsSteps.RetailFilters],
            })
        }
    }

    return (
        <ListItem
            sx={{
                background: env === EnvType.FOOD_SERVICE_CROSS ? DARK_GREY : PRIMARY,
                borderRadius: '5px',
                padding: 0,
                mt: '2vh',
            }}
            onClick={handleClick}
        > {env === EnvType.FOOD_SERVICE_CROSS ? (

            <Tooltip title="Cette fonctionnalité sera disponible prochainement">
                <ListItemButton sx={{ padding: '0.8vh 0.4vw' }}>
                    <ListItemIcon
                        sx={{
                            minWidth: { xs: '40px', md: '3vw' },
                            '& svg': {
                                width: { xs: '22px', md: '2.5vw' },
                                height: { xs: '22px', md: '2.5vh' },
                            },
                        }}
                    >
                        <LockIcon sx={{ color: "disable" }} />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            '& span': {
                                color: "disable",
                                textDecoration: 'none',
                                fontSize: { xs: '16px', md: '1.6vmin' },
                            },
                        }}
                        primary={'Ajouter une vue'}
                    />
                </ListItemButton>
            </Tooltip>) : (<ListItemButton sx={{ padding: '0.8vh 0.4vw' }}>
                <ListItemIcon
                    sx={{
                        minWidth: { xs: '40px', md: '3vw' },
                        '& svg': {
                            width: { xs: '22px', md: '2.5vw' },
                            height: { xs: '22px', md: '2.5vh' },
                        },
                    }}
                >
                    <AddIcon sx={{ color: WHITE }} />
                </ListItemIcon>
                <ListItemText
                    sx={{
                        '& span': {
                            color: WHITE,
                            textDecoration: 'none',
                            fontSize: { xs: '16px', md: '1.6vmin' },
                        },
                    }}
                    primary={'Ajouter une vue'}
                />
            </ListItemButton>)}
        </ListItem>
    )
}

const getIcon = (type: ViewTypes): JSX.Element => {
    switch (type) {
        case ViewTypes.Dashboard:
            return <DashboardOutlinedIcon />
        case ViewTypes.Map:
            return <MapOutlinedIcon />
        default:
            return <FormatListBulletedOutlinedIcon />
    }
}

const ViewsNavigation = () => {
    const [isHover, setHover] = useState<boolean>(false)
    const views = useAppSelector((state) => state.views.views)
    const { env } = useAppSelector((state) => state.global)
    const { _id } = useAppSelector((state) => state.user.user)
    const { pathname } = useLocation()
    const dispatch = useAppDispatch()
    const _getViews = useCallback(
        async (distributor: string) => await dispatch(fetchViews(distributor)),
        [dispatch]
    )

    useEffect(() => {
        if (_id) {
            _getViews(env.name)
        }
    }, [_id, env.name])

    const navigationData = views.map(
        (view, index) =>
            ({
                id: view._id,
                label: view.name,
                link: `/${view.environment}/${view.distributor}/vues/${view._id}`,
                icon: getIcon(view.type),
                distributor: view.distributor,
                env: view.environment,
                shared: view.createConfig.shared,
                usersToShare: view.createConfig.usersToShare,
                creator: {
                    firstname: view.createConfig.creatorFirstname,
                    lastname: view.createConfig.creatorLastname,
                    email: view.createConfig.creatorEmail
                }
            }) as ViewNavigationData
    )

    return (
        <Grid
            sx={{
                width: 'fill-available',
                '& .MuiListItem-button #viewListItem': {
                    visible: 'visible',
                },
            }}
        >
            <Grid container wrap="nowrap" alignItems="center">
                <PrimaryText width="inherit" noWrap>
                    Mes vues
                </PrimaryText>
                <hr
                    style={{
                        margin: '0 1vh',
                        width: '100%',
                        height: '1px',
                        border: 'none',
                        background: GREY,
                    }}
                />
            </Grid>
            <AddViewButton env={env.type} />
            <List
                onMouseOver={() => {
                    setHover(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                }}
                sx={
                    isHover
                        ? {
                            width: 'fill-available',
                            maxHeight: '24vh',
                            marginTop: '2vh',
                            overflowX: 'auto',
                        }
                        : {
                            width: 'fill-available',
                            maxHeight: '24vh',
                            marginTop: '2vh',
                            overflow: 'auto',
                            '&::-webkit-scrollbar': {
                                width: 0,
                            },
                        }
                }
            >
                {navigationData.map((data) => {
                    const isActive = pathname.replace('%20', ' ') === data.link

                    return (
                        <ViewsNavigationItem
                            key={data.id}
                            item={data}
                            isActive={isActive}
                        />
                    )
                })}
            </List>
        </Grid>
    )
}

export default ViewsNavigation
