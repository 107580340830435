import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../../redux/hooks'
import Loading from '../../../../../../components/features/screens/LoadingScreen'
import { Grid } from '@mui/material'
import ChartBlock from '../../../../../../components/features/ChartBlock'
import { EvolLineChart } from './EvolLineChart'
import { MultiAxes } from './MultiAxes'
import { ProductsList } from './ProductsList'
import { ProductsListOthers } from './ProductsListOthers'
import FsKpis, { ConfigKPIs } from '../../Dashboard/FsKpis'
import {
    PrimaryText,
    SecondaryText,
} from '../../../../../../components/ui/Text'
import {
    EnvName,
    FsFFRKPIs,
    FsTGMKPIs,
} from '../../../../../../redux/user/userTypes'
import { DrillDownType } from '../../../../../../components/features/drillDowns/ProductsDrillDownContent'

export const FsPromoPage = ({
    promoGraphTitle,
    sellInGraphTitle,
    KPIsConfig,
    promoGraphCurveDataName,
    sellInGraphCurveDataName,
    drillDownType,
}: {
    promoGraphTitle: string
    sellInGraphTitle: string
    KPIsConfig: ({
        KPIs,
        envName,
    }: {
        KPIs: FsTGMKPIs | FsFFRKPIs
        envName: EnvName
    }) => ConfigKPIs[]
    promoGraphCurveDataName: string
    sellInGraphCurveDataName: string
    drillDownType: DrillDownType
}) => {
    const [isLoadingChart, setLoadingChart] = useState(true)
    const { isLoading } = useAppSelector((state) => state.global)
    const promotions = useAppSelector((state) => state.fsProducts.promotions)

    useEffect(() => {
        if (promotions) {
            setLoadingChart(false)
        }
    }, [promotions])

    return (
        <Grid
            container
            direction="column"
            sx={{
                width: '100%',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
        >
            <Grid>
                <FsKpis KPIsConfig={KPIsConfig} />
            </Grid>
            {(!promotions && isLoadingChart) ||
                (isLoading && (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Loading text={false} height="40vh" />
                        <PrimaryText bold>
                            Le chargement peut prendre jusqu'à 1 min
                        </PrimaryText>
                        <SecondaryText>Promis, on y travaille !</SecondaryText>
                    </Grid>
                ))}
            {promotions && !isLoading && (
                <Grid>
                    <Grid
                        width="100%"
                        container
                        gap={2}
                        mb="1vw"
                        flexWrap="nowrap"
                    >
                        <Grid flex={1}>
                            <ChartBlock
                                title={`Comparaison promo, sell-in et sell-out`}
                                titleHasHover={false}
                            >
                                <EvolLineChart
                                    evolution={promotions.evolution}
                                    period={promotions.period}
                                />
                            </ChartBlock>
                        </Grid>
                        <Grid flex={1}>
                            <ChartBlock
                                title={promoGraphTitle}
                                titleHasHover={false}
                            >
                                <MultiAxes
                                    period={promotions.period}
                                    multiAxes={promotions.promoGraph}
                                    curveDataName={promoGraphCurveDataName}
                                    curveEvolName="€ investi par kg"
                                />
                            </ChartBlock>
                        </Grid>
                        <Grid flex={1}>
                            <ChartBlock
                                title={sellInGraphTitle}
                                titleHasHover={false}
                            >
                                <MultiAxes
                                    period={promotions.period}
                                    multiAxes={promotions.sellInGraph}
                                    curveDataName={sellInGraphCurveDataName}
                                    curveEvolName="Prix de vente moyen par kg"
                                />
                            </ChartBlock>
                        </Grid>
                    </Grid>
                    <Grid>
                        <ChartBlock title={`Top Produits / Autres produits`}>
                            <ProductsList
                                productsList={promotions.list.topProducts}
                                drillDownType={drillDownType}
                            />
                        </ChartBlock>
                    </Grid>
                    <Grid
                        item
                        mt="1vw"
                        sx={{
                            '& .MuiDataGrid-row:first-child': {
                                background: '#CFFF97',
                            },
                        }}
                    >
                        <ProductsListOthers
                            productsList={promotions.list.otherProducts}
                            drillDownType={drillDownType}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}
