import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { TransgourmetColumnsWarehousesPage } from '../utils'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import FsWarehousesPage from '../Pages/FsWarehousesPage'
import { WarehousesLayout } from '../layout/WarehousesLayout'

export const WarehousesPageTGM = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    const filters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        products: [],
        clients: [],
        categories: [],
    }
    return (
        <PrivatePage title="Dépôts" attribute={0} period>
            <Filters
                filtersType={FiltersType.FS}
                filters={filters}
                page={PagesName.WAREHOUSES}
            />
            <WarehousesLayout filters={filters}>
                <FsWarehousesPage
                    columns={TransgourmetColumnsWarehousesPage()}
                    drillDownType={DrillDownType.WAREHOUSESTGM}
                />
            </WarehousesLayout>
        </PrivatePage>
    )
}
