import { Grid } from '@mui/material'
import React, { useEffect, useCallback, useState } from 'react'
import { LineChartProduct } from './LineChartProduct'
import {
    type GridColDef,
    type GridColumnHeaderParams,
    type GridRenderCellParams,
} from '@mui/x-data-grid'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { DataTableComponent } from '../../../DataTableComponent'
import { PrimaryText, SecondaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import NumberWithSpace from '../../../../../components/ui/NumberWithSpace'
import { ActiveRetailFilters } from '../../../../../redux/filters/filtersTypes'
import {
    fetchFilteredretailProducts,
    setProductsRetail,
} from '../../../../../redux/retailProducts/retailProductsSlice'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'
import Tendency, { TooltipMessage } from '../../../../../components/ui/Tendency'
import { Units } from '../../../../../redux/global/globalTypes'
import DrillDown from '../../../../../components/features/drillDowns/DrillDown'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { ProductItemRetail } from '../../../../../redux/retailProducts/retailProductsTypes'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'

const columns: GridColDef[] = [
    {
        field: 'productName',
        headerName: 'Références',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return <PrimaryText>{params.colDef.headerName}</PrimaryText>
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'ean',
        headerName: 'EAN',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'stores',
        headerName: 'PDV distributeurs',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceStores',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.differenceStores}
                    unit={Units.none}
                    tooltip={TooltipMessage.STORES}
                />
            )
        },
    },
    {
        field: 'values',
        headerName: 'Volume (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return <PrimaryText color={BLACK}>Volume (UVC)</PrimaryText>
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'difference',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.difference}
                    unit={Units.uvc}
                    tooltip={TooltipMessage.VOLUME}
                />
            )
        },
    },
    {
        field: 'oldValues',
        headerName: 'Volume A-1 (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'VMM',
        headerName: `VMM (${Units.uvcPerStorePerMonth})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceVMM',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <Tendency
                        difference={params.row.differenceVMM}
                        unit={Units.uvcPerStorePerMonth}
                        tooltip={TooltipMessage.VMM}
                    />
                </SecondaryText>
            )
        },
    },
    {
        field: 'oldVMM',
        headerName: `VMM A-1 (${Units.uvcPerStorePerMonth})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            )
        },
    },
]

const CarrefourProductsPage = () => {
    const dispatch = useAppDispatch()
    const { brand } = useAppSelector((state) => state.user.user)
    const { products } = useAppSelector((state) => state.retailProducts)
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { isLoading, env } = useAppSelector((state) => state.global)
    const _fetchProducts = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchFilteredretailProducts(filters))
        },
        [dispatch]
    )
    const [fieldSelected, setFieldSelected] = useState<ProductItemRetail>()
    const [isOpen, setOpen] = useState(false)
    const [value, setValue] = useState(0)

    const _setProductsRetail = useCallback(() => {
        dispatch(setProductsRetail(null))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setProductsRetail()
    }

    useEffect(() => {
        if (brand) {
            _fetchProducts(activeRetailFilters)
        }
    }, [brand, activeRetailFilters, _fetchProducts])

    if (isLoading || !products) {
        return <Loading />
    }

    if (env.status === 'onload-init') {
        return <WaitingScreen />
    }

    if (products.list.length === 0) {
        return <NoDataScreen />
    }

    return (
        <Grid
            container
            direction="column"
            sx={{
                height: '86vh',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
            flexWrap="nowrap"
        >
            <Grid item>
                {products && (
                    <ChartBlock title="Volume vendu par référence (UVC) ">
                        <LineChartProduct />
                    </ChartBlock>
                )}
            </Grid>
            <Grid height="100%" item mt="1vw">
                {products && (
                    <ChartBlock
                        title={`Liste des références (${products.list.length})`}
                    >
                        <Grid height="100%">
                            <DrillDown
                                type={DrillDownType.RETAILPRODUCTS}
                                open={isOpen}
                                title={
                                    fieldSelected &&
                                    `${Object.values(fieldSelected)[2]
                                        .productName
                                    }`
                                }
                                value={value}
                                setValue={setValue}
                                row={fieldSelected}
                                handleClose={handleClose}
                            />
                            <DataTableComponent
                                type={DrillDownType.RETAILPRODUCTS}
                                setOpen={setOpen}
                                setFieldSelected={setFieldSelected}
                                setValue={setValue}
                                rows={products.list}
                                columns={columns}
                                getRowId={(row) => row.ean}
                            />
                        </Grid>
                    </ChartBlock>
                )}
            </Grid>
        </Grid>
    )
}

export default CarrefourProductsPage
