import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import { getCategoriesMetroColumns, getFSRows } from './setupDrillDown'
import { DrillDownType } from './ProductsDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import { fetchDrillDownCategories } from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'

export const ClientMetroDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
    type,
}: DrillDownContentProps) => {
    const [isLoadingCategories, setLoadingCategories] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownProducts, drillDownCategories } = useAppSelector(
        (state) => state.fsProducts
    )

    const categorieslength = drillDownCategories
        ? drillDownCategories.length
        : ''

    const _fetchDrillDownCategories = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownCategories(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: [row.id],
        products: activeFsFilters.products,
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataCategories = async () => {
            await _fetchDrillDownCategories(filters)
        }

        fetchDataCategories().then(() => setLoadingCategories(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations']}
            row={row}
            value={value}
            type={DrillDownType.CLIENTSMETRO}
        >
            {/*  <CustomTabPanel value={value} index={1}>
                {drillDownCategories && !isLoadingCategories && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getCategoriesMetroColumns()}
                        message={''}
                    />
                )}
                {isLoadingCategories && <Loading text={false} height="54vh" />}
            </CustomTabPanel> */}
        </CommonTabs>
    )
}
