import React, { useEffect, useState } from "react"
import Joyride, { CallBackProps } from "react-joyride"
import { useNavigate } from "react-router-dom";
import { importSteps } from "./steps/importSteps";
import { dashboardEndSteps, dashboardStep } from "./steps/dashboardSteps";
import { storesPageSteps } from "./steps/StoresSteps";
import { PRIMARY } from "../../ui/Colors";

const START_LINK = "/parametres/import-retail"

enum Steps {
    IMPORT,
    DASHBOARD,
    STORES,
    DASHBOARDEND
}

const RetailProductTour = () => {
    const navigate = useNavigate()
    const [{ stepIndex, run, steps, type }, setProductTour] = useState({ stepIndex: 0, run: false, steps: importSteps, type: Steps.IMPORT })

    const handleNextStepClik = () => {
        setProductTour((data) => {
            if (data.stepIndex === 1) return { stepIndex: 2, run: false, steps: data.steps, type: data.type }
            else if (data.stepIndex === 2) return { stepIndex: 3, run: false, steps: data.steps, type: data.type }
            else return { stepIndex, run, steps: importSteps, type }
        })

        setTimeout(() => {
            setProductTour(data => ({ run: true, stepIndex: data.stepIndex, steps: importSteps, type: data.type }));
        }, 400);
    }

    useEffect(() => {
        // Set env on import retail
        navigate(START_LINK)

        if (!localStorage.getItem('productTour')) {
            setProductTour(data => ({ run: true, stepIndex, steps, type }));
        }
    }, [])

    useEffect(() => {
        const menu = document.getElementById("env-selector")
        if (menu) menu.addEventListener('click', handleNextStepClik)
    }, [window.location.pathname])

    const handleProductTourCallback = (data: CallBackProps) => {
        const {
            action,
            index,
            step: {
                data: { next, previous },
            },
            type: productType,
        } = data;
        const isPreviousAction = action === 'prev';

        if (productType === "tour:end") {
            localStorage.setItem('productTour', 'true')
        }

        if (productType === 'step:after') {
            if (index != 1 || type != Steps.IMPORT) setProductTour((data) => {
                return ({ stepIndex: data.stepIndex + 1, run: true, steps, type: data.type })
            })

            // Handle Reset
            if (index === 2 && type === Steps.IMPORT) {
                setProductTour((data) => ({ stepIndex: 0, run: false, steps: dashboardStep, type: Steps.DASHBOARD }))
                navigate(next);
                setTimeout(() => {
                    setProductTour(data => ({ run: true, stepIndex: data.stepIndex, steps: data.steps, type: data.type }));
                }, 900);
            } else if (index === 3 && type === Steps.DASHBOARD) {
                setProductTour((data) => ({ stepIndex: 0, run: false, steps: storesPageSteps, type: Steps.STORES }))
                navigate(next);
                setTimeout(() => {
                    setProductTour(data => ({ run: true, stepIndex: data.stepIndex, steps: data.steps, type: data.type }));
                }, 900);
            } else if (index === 1 && type === Steps.STORES) {
                setProductTour((data) => ({ stepIndex: 0, run: false, steps: dashboardEndSteps, type: Steps.DASHBOARDEND }))
                navigate(next);
                setTimeout(() => {
                    setProductTour(data => ({ run: true, stepIndex: data.stepIndex, steps: data.steps, type: data.type }));
                }, 900);
            } else if (next) {
                if (index < 5) {
                    setProductTour({ stepIndex, run: false, steps, type });

                    if (previous || next) {
                        navigate(isPreviousAction && previous ? previous : next);
                        setProductTour({ run: true, stepIndex, steps, type })
                    }
                }

                if (index === 4) {
                    if (isPreviousAction && previous) {
                        setProductTour({ run: false, stepIndex, steps, type });
                        if (previous || next)
                            navigate(previous);
                    } else {
                        setProductTour({ run: false, stepIndex, steps, type });
                    }
                }
            }
        }

        // Handle Karyon log
    };

    return <Joyride
        callback={handleProductTourCallback}
        continuous
        run={run}
        disableScrolling
        scrollToFirstStep
        hideBackButton
        showProgress
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
            overlay: { height: "120vh" },
            options: {
                zIndex: 10000,
                primaryColor: PRIMARY
            },
        }} />
}

export default RetailProductTour