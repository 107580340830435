import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { EditValues, FormProps } from './ProfilPage'
import { type FormikProps } from 'formik'
import { EnhancedField, TextInput } from '../../../../components/ui/Inputs'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import DistributerSelector from '../../../../components/features/DistributerSelector'

interface InputsProfilProps {
    label: string
    type: string
    name: string
    list?: string | string[]
    disabled: boolean
}
export const InputsProfilPage = ({
    label,
    type,
    name,
    list,
    disabled = false,
    ...props
}: InputsProfilProps & FormikProps<EditValues> & FormProps) => {
    const toggleDistributer = (distributer: string) => {
        const isInclude = props.values[name].includes(distributer)
        if (isInclude) {
            props.setFieldValue(
                name,
                props.values[name].filter((el) => el !== distributer)
            )
        } else {
            props.setFieldValue(name, [...props.values[name], distributer])
        }
    }
    let listToDisplay = []

    // Define the reference list of distributors.
    const distributerList: string[] =
        typeof list === 'string' ? props.values[list] : list

    // if it's a type distributer so we keep the general list of all distributors + we add the others
    if (type === 'distributer') {
        const myStartingList =
            name === 'distributerRetail'
                ? props.values.distributerRetailStartingList
                : props.values.distributerFSStartingList

        const myListToDisplay = myStartingList.filter(
            (dist) => !distributerList.includes(dist)
        )

        listToDisplay = [...distributerList, ...myListToDisplay]
    }

    // if it's type distributerData, we take the list of all the distributor the user has selected
    if (type === 'distributerData') {
        listToDisplay = distributerList
    }

    if (type === 'list') {
        if (name === 'companyBrands') {
            listToDisplay = props.company.brands
        } else if (name === 'members' && props.company.members) {
            listToDisplay = props.company.members.map((member) =>
                member.lastname !== ' '
                    ? member.firstname + ' ' + member.lastname
                    : member.email
            )
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="40vw"
        >
            <Grid item flex={2}>
                <PrimaryText color={BLACK}>{label}</PrimaryText>
            </Grid>
            <Grid container flex={5}>
                {type === 'list' &&
                    listToDisplay.map((element) => (
                        <Grid container>
                            <List sx={{ p: 0 }}>
                                <ListItem sx={{ p: 0, m: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRightIcon fontSize="small" />
                                    </ListItemIcon>
                                    <PrimaryText color={BLACK}>
                                        {element}
                                    </PrimaryText>
                                </ListItem>
                            </List>
                        </Grid>
                    ))}
                {type !== 'distributer' &&
                    type !== 'distributerData' &&
                    type !== 'list' && (
                        <EnhancedField
                            type={type}
                            name={name}
                            margin="normal"
                            disabled={disabled}
                            component={TextInput}
                        />
                    )}
                {(type === 'distributer' || type === 'distributerData') && (
                    <Grid container>
                        <DistributerSelector
                            list={listToDisplay}
                            values={props.values[name]}
                            selectorHandler={toggleDistributer}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
