import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getFSRows,
    getWarehousesColumns,
    getProductsColumns,
} from './setupDrillDown'
import { DrillDownType } from './ProductsDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownProducts,
    fetchDrillDownWarehouses,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { EnvName } from '../../../redux/user/userTypes'

export interface DrillDownContentProps {
    type: DrillDownType
    row: any
    value: number
    isFirstVisit: boolean
    handleChange: (event: React.SyntheticEvent, newValue: number) => void
}

export const ClientFFRDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
    type,
}: DrillDownContentProps) => {
    const [isLoadingProducts, setLoadingProducts] = useState(true)
    const [isLoadingWarehouses, setLoadingWarehouses] = useState(true)
    const dispatch = useAppDispatch()
    const { env } = useAppSelector((state) => state.global)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownProducts, drillDownWarehouses } = useAppSelector(
        (state) => state.fsProducts
    )

    const productslength = drillDownProducts ? drillDownProducts.length : ''
    const warehouseslength = drillDownWarehouses
        ? drillDownWarehouses.length
        : ''

    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )
    const _fetchDrillDownWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehouses(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: [row.id],
        products: activeFsFilters.products,
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataProducts = async () => {
            await _fetchDrillDownProducts(filters)
        }
        const fetchDataWarehouses = async () => {
            await _fetchDrillDownWarehouses(filters)
        }

        fetchDataWarehouses().then(() => setLoadingWarehouses(false))
        fetchDataProducts().then(() => setLoadingProducts(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Références (${productslength})`,
                `Dépôts (${warehouseslength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoadingProducts && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getProductsColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.PRODUCTS,
                            value: row.row.products,
                            oldValue: row.row.productsLastYear,
                        })}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            {env.name !== EnvName.TRANSGOURMET && (
                <CustomTabPanel value={value} index={2}>
                    {drillDownWarehouses && !isLoadingWarehouses && (
                        <DataTableDrillDown
                            isFirstVisit={isFirstVisit}
                            rows={getFSRows(drillDownWarehouses)}
                            columns={getWarehousesColumns(type)}
                            message={messageToDisplay({
                                type: DrillDownType.WAREHOUSES,
                                value: row.row.warehouses,
                                oldValue: row.row.warehousesLastYear,
                            })}
                        />
                    )}
                    {isLoadingWarehouses && (
                        <Loading text={false} height="54vh" />
                    )}
                </CustomTabPanel>
            )}
        </CommonTabs>
    )
}
