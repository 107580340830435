import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { SignUpData } from '../../pages/public/authentication/signUp/steps/SignUpResume'
import { AppThunk } from '../store'
import {
    Env,
    User,
    UserInvitation,
    UserInvitationValidityResponse,
    UserInviteResponse,
    UsersRole,
    UsersStatus,
    userState,
} from './userTypes'

const initialState: userState = {
    user: {
        _id: '',
        firstname: '',
        email: '',
        lastname: '',
        job: '',
        brand: '',
        retail: null,
        fs: null,
        env: [],
        modules: [],
        role: UsersRole.USER,
        status: UsersStatus.DEACTIVATED,
        useCases: [],
    },
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.user = initialState.user
        },
        setUser: (state, action: PayloadAction<User>) => {
            const user = action.payload

            if (user) {
                state.user = action.payload
            }
        },
        updateUser: (
            state,
            action: PayloadAction<{ update: Partial<User> }>
        ) => {
            state.user = { ...state.user, ...action.payload.update }
        },
        addEnv: (state, action: PayloadAction<{ env: Env }>) => {
            state.user.env = [...state.user.env, ...[action.payload.env]]
        },
        updateEnv: (state, action: PayloadAction<{ env: Env }>) => {
            state.user.env = state.user.env.filter(
                (env) => env.name !== action.payload.env.name
            )
            state.user.env = [...state.user.env, ...[action.payload.env]]
        },
    },
})

export const { setUser, resetUser, updateUser, addEnv, updateEnv } =
    userSlice.actions

export default userSlice.reducer

export const addUser =
    (userData: SignUpData, supabaseId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const userId = await client
                .put(`/user/new`, {
                    supabaseId,
                    email: userData.email,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    job: userData.job,
                    useCases: userData.useCases,
                    ...(userData.phoneNumber
                        ? { phoneNumber: userData.phoneNumber }
                        : null),
                })
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchUser =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            const user = await client
                .get(`/user`)
                .then((response) => {
                    return response.data as User
                })
                .catch((err) => {
                    console.log('ERROR FETCH USER: ', err)
                    return null
                })
            dispatch(setUser(user))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const logUserConnection =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            await client
                .post(`/activities/log-connection`)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR FETCH USER: ', err)
                    return null
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchUpdateUser =
    (update: Partial<User>): AppThunk<Promise<{ modified: number }>> =>
    async (dispatch, getState) => {
        try {
            const updatedUser = {
                firstname: update.firstname,
                lastname: update.lastname,
                job: update.job,
                phoneNumber: update.phoneNumber,
            } as User

            dispatch(updateUser({ update: updatedUser }))

            const user = await client
                .put(`/user`, updatedUser)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return []
                })
            return user
        } catch (e) {
            console.log('ERROR : ', e)
            return {}
        }
    }

export const addUserEnv =
    (userId: string, env: Env): AppThunk<Promise<User[]>> =>
    async (dispatch, getState) => {
        try {
            const user = await client
                .post(`/user/env`, {
                    name: env.name,
                    type: env.type,
                    status: env.status,
                })
                .then((response) => {
                    dispatch(addEnv({ env }))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return []
                })

            return user
        } catch (e) {
            console.log('ERROR : ', e)
            return {}
        }
    }

export const UpdateUserEnv =
    (userId: string, env: Env): AppThunk<Promise<User[]>> =>
    async (dispatch, getState) => {
        try {
            const user = await client
                .put(`/user/env`, {
                    name: env.name,
                    type: env.type,
                    status: env.status,
                })
                .then((response) => {
                    dispatch(updateEnv({ env }))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return []
                })

            return user
        } catch (e) {
            console.log('ERROR : ', e)
            return {}
        }
    }

export const inviteUser =
    (data: UserInvitation): AppThunk<Promise<UserInviteResponse>> =>
    async (dispatch, getState) => {
        const newUser = {
            email: data.email,
            role: data.role,
        }

        try {
            const sendInvitation = await client
                .post(`/invitations`, newUser)
                .then((response) => {
                    return response
                })

            if (sendInvitation.data.result) {
                return { result: { success: sendInvitation.data.result } }
            } else {
                return { result: { error: sendInvitation.data.message } }
            }
        } catch (e) {
            console.log('ERROR :', e)
            return { result: { error: 'erreur' } }
        }
    }

export const checkInvitationValidity =
    (invitationId: string): AppThunk<Promise<UserInvitationValidityResponse>> =>
    async (dispatch, getState) => {
        try {
            const activityValidaty = await client
                .get(`/invitations/${invitationId}`)
                .then((response) => {
                    dispatch(
                        updateUser({
                            update: {
                                email: response.data.email,
                            },
                        })
                    )
                    return response
                })

            return activityValidaty.data
        } catch (e) {
            console.log('ERROR :', e)
            return { validity: false, email: ' ' }
        }
    }
