import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import DoughnutChart from '../../../../../components/features/foodService/dashboard/graph/DoughnutChart'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid } from '@mui/material'
import { MetroNoClientsData, WarningBannerClientSegmentationMetroFsCross } from '../../Pages/Dashboard/FsSegmentation'

const FsCrossSegmentation = ({
    setFieldSelected,
    setOpen,
    title,
}: {
    setFieldSelected?: (field: any) => void
    setOpen?: (open: boolean) => void
    title: string
}) => {
    const { clients } = useAppSelector((state) => state.global.fsCrossDashboardData)
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)

    return (
        <ChartBlock title={title}>
            <Grid height="100%" container justifyContent="center">
                <WarningBannerClientSegmentationMetroFsCross />
                {JSON.stringify(activeFsCrossFilters.distributors) === JSON.stringify(["Metro"]) && (
                    <MetroNoClientsData />
                )}
                {clients && JSON.stringify(activeFsCrossFilters.distributors) !== JSON.stringify(["Metro"]) && <DoughnutChart
                    setOpen={setOpen}
                    setFieldSelected={setFieldSelected}
                />}
            </Grid>
        </ChartBlock>
    )
}

export default FsCrossSegmentation
