import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../../../redux/filters/filtersTypes'
import { fetchDrillDownPromoInformations } from '../../../../../../redux/fsProducts/fsProductsSlice'
import {
    CustomTabPanel,
    InformationsTable,
} from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import { getColumnsInformationTab } from '../../../../../../components/features/drillDowns/setupDrillDown'
import Loading from '../../../../../../components/features/screens/LoadingScreen'
import { DrillDownType } from '../../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { SubTitle } from '../../../../../../components/ui/Text'
import { DataCartDrillDownPromoInfo } from './DataCardDrillDownPromoInfo'
import { Units } from '../../../../../../redux/global/globalTypes'

export const round2digit = (num: number) => {
    return Math.round(num * 100) / 100
}

export const PromoInformationsTab = ({
    row,
    value,
}: {
    row: any
    value: number
}) => {
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const [isLoading, setLoading] = useState(true)

    const _fetchDrillDownPromoInformations = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownPromoInformations(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchData = async () => {
            await _fetchDrillDownPromoInformations(filters)
        }

        fetchData().then(() => setLoading(false))
    }, [])
    const { drillDownPromoInformations } = useAppSelector(
        (state) => state.fsProducts
    )

    const rows = getColumnsInformationTab(DrillDownType.PROMO, row)

    return (
        <CustomTabPanel value={value} index={0}>
            {isLoading && <Loading text={false} height="54vh" />}
            {drillDownPromoInformations && !isLoading && (
                <Grid container wrap="nowrap">
                    <InformationsTable rows={rows} />
                    <Grid
                        container
                        alignItems="flex-start"
                        flexDirection="column"
                    >
                        <SubTitle>Sell-out</SubTitle>
                        <Grid container flexDirection="row" wrap="nowrap">
                            <DataCartDrillDownPromoInfo
                                title="Volume de ventes total"
                                value={Math.round(
                                    drillDownPromoInformations.sellOutKPI.volume
                                )}
                                unit={Units.kg}
                                difference={
                                    drillDownPromoInformations.sellOutKPI
                                        .volume -
                                    drillDownPromoInformations.sellOutKPI
                                        .volumeLastYear
                                }
                                notRoundable={false}
                            />
                            <DataCartDrillDownPromoInfo
                                title="Nombre de clients"
                                value={Math.round(
                                    drillDownPromoInformations.sellOutKPI
                                        .clients
                                )}
                                unit={Units.clients}
                                difference={
                                    drillDownPromoInformations.sellOutKPI
                                        .clients -
                                    drillDownPromoInformations.sellOutKPI
                                        .clientsLastYear
                                }
                                notRoundable={false}
                            />
                            <DataCartDrillDownPromoInfo
                                title="VMM"
                                value={Math.round(
                                    drillDownPromoInformations.sellOutKPI.vmm
                                )}
                                unit={Units.kg}
                                difference={
                                    drillDownPromoInformations.sellOutKPI.vmm -
                                    drillDownPromoInformations.sellOutKPI
                                        .vmmLastYear
                                }
                                notRoundable={false}
                            />
                            <DataCartDrillDownPromoInfo
                                title="Nombre de dépôts"
                                value={Math.round(
                                    drillDownPromoInformations.sellOutKPI
                                        .warehouses
                                )}
                                unit={Units.warehouses}
                                difference={
                                    drillDownPromoInformations.sellOutKPI
                                        .warehouses -
                                    drillDownPromoInformations.sellOutKPI
                                        .warehousesLastYear
                                }
                                notRoundable={false}
                            />
                        </Grid>
                        <Grid container flexDirection="row">
                            <Grid item mt="2vh" mr="2vw">
                                <SubTitle>Sell-in</SubTitle>
                                <DataCartDrillDownPromoInfo
                                    title="Prix moyen net"
                                    value={round2digit(
                                        drillDownPromoInformations.sellInKPI
                                            .netAveragePrice
                                    )}
                                    unit={Units['€/kg']}
                                    difference={
                                        drillDownPromoInformations.sellInKPI
                                            .netAveragePrice -
                                        drillDownPromoInformations.sellInKPI
                                            .netAveragePriceLastYear
                                    }
                                    notRoundable
                                />
                            </Grid>
                            <Grid item mt="2vh">
                                <SubTitle>Promo</SubTitle>
                                <DataCartDrillDownPromoInfo
                                    title="€ investis/kg"
                                    value={round2digit(
                                        drillDownPromoInformations.promoKPI
                                            .investmentPerVolume
                                    )}
                                    unit={Units['€/kg']}
                                    difference={
                                        drillDownPromoInformations.promoKPI
                                            .investmentPerVolume -
                                        drillDownPromoInformations.promoKPI
                                            .investmentPerVolumeLastYear
                                    }
                                    notRoundable={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </CustomTabPanel>
    )
}
