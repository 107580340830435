import {
    fsDistributor,
    retailDistributor,
} from '../../../../helpers/distributorsList'
import { Inputs } from '../../../public/authentication/signUp/steps/SignUpAccount'

interface EnhancedInput extends Inputs {
    id: number
    list?: string | string[]
    disabled?: boolean
}

interface InputsSection {
    id?: number
    title: string
    inputs: EnhancedInput[]
}

export const inputsProfil: InputsSection = {
    title: 'Informations personnelles',
    inputs: [
        {
            id: 0,
            type: 'email',
            name: 'email',
            label: 'Adresse email',
            disabled: true,
        },
        {
            id: 2,
            type: 'text',
            name: 'firstname',
            label: 'Prénom',
        },
        {
            id: 3,
            type: 'text',
            name: 'lastname',
            label: 'Nom',
        },
        { id: 4, type: 'tel', name: 'phoneNumber', label: 'Téléphone' },
        { id: 5, type: 'text', name: 'job', label: 'Poste' },
    ],
}

export const inputsCompanyForAdmin: InputsSection = {
    id: 2,
    title: 'Informations entreprise',
    inputs: [
        {
            id: 0,
            type: 'text',
            name: 'companyName',
            label: 'Entreprise',
            disabled: true,
        },
        {
            id: 1,
            type: 'list',
            name: 'members',
            label: 'Membres',
            disabled: true,
        },
    ],
}

export const inputsCompanyForUser: InputsSection = {
    id: 1,
    title: 'Informations entreprise',
    inputs: [
        {
            id: 0,
            type: 'text',
            name: 'companyName',
            label: 'Entreprise',
            disabled: true,
        },
    ],
}

export const inputsdata: InputsSection = {
    id: 3,
    title: 'Données de distribution',
    inputs: [
        {
            id: 0,
            type: 'distributer',
            list: retailDistributor,
            name: 'distributerRetail',
            label: 'Distributeurs Retail',
        },
        {
            id: 1,
            type: 'distributerData',
            list: 'distributerRetail',
            name: 'dataRetail',
            label: 'Données Retail',
        },
        {
            id: 2,
            type: 'distributer',
            list: fsDistributor,
            name: 'distributerFS',
            label: 'Distributeurs RHD (FoodService)',
        },
        {
            id: 3,
            type: 'distributerData',
            list: 'distributerFS',
            name: 'dataFS',
            label: 'Données RHD (FoodService)',
        },
    ],
}

export const menuForAdmin = [
    'Invitations',
    'Informations personnelles',
    'Informations entreprise',
    'Données de distribution',
]
export const menuForUser = [
    'Informations personnelles',
    'Informations entreprise',
]
