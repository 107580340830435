import React, { useCallback, useEffect, useState } from 'react'
import {
    getClientsColumns,
    getFSRows,
    getFsProductsNotDistributedColumns,
    getProductsColumns,
} from './setupDrillDown'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
} from './othersDrillDownComponents'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import { fetchDrillDownProducts, fetchDrillDownProductsNotDistributed } from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'

export const WarehousesMetroDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
    type,
}: DrillDownContentProps) => {
    const [isLoadingProducts, setLoadingProducts] = useState(true)
    const [isLoadingProductsNotDistributed, setLoadingProductsNotDistributed] = useState(true)
    const { unit } = useAppSelector((state) => state.global)

    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )
    const _fetchDrillDownProductsNotDistributed = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProductsNotDistributed(filters))
        },
        [dispatch]
    )

    const { drillDownProducts, drillDownProductsNotDistributed } = useAppSelector((state) => state.fsProducts)
    const productslength = drillDownProducts ? drillDownProducts.length : ''
    const productsNotDistributedLength = drillDownProductsNotDistributed ? drillDownProductsNotDistributed.length : ''


    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: activeFsFilters.products,
        categories: activeFsFilters.categories,
        regions: [row.id],
    }

    useEffect(() => {
        const fetchDataProducts = async () => {
            await _fetchDrillDownProducts(filters)
        }
        const fetchDataProductsNotDistributed = async () => {
            await _fetchDrillDownProductsNotDistributed(filters)
        }

        fetchDataProductsNotDistributed().then(() => setLoadingProductsNotDistributed(false))
        fetchDataProducts().then(() => setLoadingProducts(false))
    }, [])
    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations', `Références (${productslength})`, `Références non distribuées (${productsNotDistributedLength})`,]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoadingProducts && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getProductsColumns(type, unit)}
                        message={''}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownProductsNotDistributed && !isLoadingProductsNotDistributed && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={drillDownProductsNotDistributed}
                        columns={getFsProductsNotDistributedColumns()}
                        message={`Psssst, ci-dessous la précieuse liste des références que vous pourriez pousser dans le dépôt ${row.row.warehouse}`}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
