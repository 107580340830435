import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getFSRows,
    getWarehousesColumns,
    getProductsColumnsForTGM,
} from './setupDrillDown'
import { DrillDownType } from './ProductsDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownProducts,
    fetchDrillDownWarehouses,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { EnvName } from '../../../redux/user/userTypes'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'

export const ClientTGMDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    const { env } = useAppSelector((state) => state.global)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    const { drillDownProducts, drillDownWarehouses } = useAppSelector(
        (state) => state.fsProducts
    )
    const [isLoadingProducts, setLoadingProducts] = useState(true)
    const [isLoadingWarehouses, setLoadingWarehouses] = useState(true)

    const productslength = drillDownProducts ? drillDownProducts.length : ''

    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )
    const _fetchDrillDownWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehouses(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: [row.id],
        products: activeFsFilters.products,
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataProducts = async () => {
            await _fetchDrillDownProducts(filters)
        }
        const fetchDataWarehouses = async () => {
            await _fetchDrillDownWarehouses(filters)
        }

        fetchDataWarehouses().then(() => setLoadingWarehouses(false))
        fetchDataProducts().then(() => setLoadingProducts(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations', `Références (${productslength})`]}
            row={row}
            value={value}
            type={DrillDownType.CLIENTSTGM}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoadingProducts && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getProductsColumnsForTGM(
                            DrillDownType.CLIENTSTGM
                        )}
                        message={messageToDisplay({
                            type: DrillDownType.PRODUCTS,
                            value: row.row.products,
                            oldValue: row.row.productsLastYear,
                        })}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            {env.name !== EnvName.TRANSGOURMET && (
                <CustomTabPanel value={value} index={2}>
                    {drillDownWarehouses && !isLoadingWarehouses && (
                        <DataTableDrillDown
                            isFirstVisit={isFirstVisit}
                            rows={getFSRows(drillDownWarehouses)}
                            columns={getWarehousesColumns(
                                DrillDownType.CLIENTS
                            )}
                            message={messageToDisplay({
                                type: DrillDownType.WAREHOUSES,
                                value: row.row.warehouses,
                                oldValue: row.row.warehousesLastYear,
                            })}
                        />
                    )}
                    {isLoadingWarehouses && (
                        <Loading text={false} height="54vh" />
                    )}
                </CustomTabPanel>
            )}
        </CommonTabs>
    )
}
