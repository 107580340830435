import { PrimaryText, Title } from '../../../ui/Text'
import React, { useSyncExternalStore } from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { PrimaryButton } from '../../../ui/Button'
import { LIGHT_GREY } from '../../../ui/Colors'
import KaryonLogo from '../../../../assets/Logo-KaryonFood-Normal.png'
import Illustration from '../../../../assets/Illustrationpagemobile.svg'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { getConfig } from '../../../../api/config'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'

const handleSupport = () => {
    window.open(
        'https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed',
        '_blank'
    )
}

const IMAGE_LIMIT = 450

export const MobilePage = () => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    return (
        <Grid container justifyContent="center" sx={{ background: LIGHT_GREY }}>
            <Paper sx={{ width: '88vw', m: '1vh', p: '5vh', height: '88vh' }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                    wrap="nowrap"
                >
                    <Grid container direction="column" alignItems="center">
                        <Box
                            component="img"
                            width="220px"
                            src={KaryonLogo}
                            alt="Logo Karyon"
                        />
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                        <Title> C'est beaucoup mieux sur ordinateur...</Title>
                        <PrimaryText>
                            Pour une expérience agréable, accédez à la
                            plateforme KaryonFood sur votre ordinateur ou
                            tablette
                        </PrimaryText>
                        <Grid mt={3}>
                            <PrimaryButton
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        getConfig().appUrl
                                    )
                                }}
                            >
                                Copier l'URL à ouvrir sur mon ordinateur
                            </PrimaryButton>
                        </Grid>
                        {height > IMAGE_LIMIT && (
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '250px', md: '35vw' },
                                    marginTop: '4em',
                                }}
                                src={Illustration}
                                alt="Logo Karyon"
                            />
                        )}
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        onClick={handleSupport}
                    >
                        <HelpOutlineIcon />
                        <PrimaryText ml={1}>Support</PrimaryText>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
