import { Dialog } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { closeViewUpdator, updateView } from '../../../redux/views/viewsSlice'
import { type StepConfig, ViewsSteps } from '../../../redux/views/viewsTypes'
import { DialogHeader } from '../Dialog/BasicDialog'
import FsFiltersStepUpdate from './steps/FsFiltersStepUpdate'
import FsSettingsStepUpdate from './steps/FsSettingsStepUpdate'
import RetailFiltersStepUpdate from './steps/RetailFiltersStepUpdate'
import RetailSettingsStepUpdate from './steps/RetailSettingsStepUpdate'
import { useNavigate } from 'react-router-dom'

const stepsSync: Record<
    string,
    (step: number, config: StepConfig, id: string) => JSX.Element
> = {
    [ViewsSteps.FsFilters]: (step: number, config: StepConfig, id: string) => (
        <FsFiltersStepUpdate step={step} config={config} viewId={id} />
    ),
    [ViewsSteps.FsSettings]: (step: number, config: StepConfig, id: string) => (
        <FsSettingsStepUpdate step={step} config={config} viewId={id} />
    ),
    [ViewsSteps.RetailFilters]: (
        step: number,
        config: StepConfig,
        id: string
    ) => <RetailFiltersStepUpdate step={step} config={config} viewId={id} />,
    [ViewsSteps.RetailSettings]: (
        step: number,
        config: StepConfig,
        id: string
    ) => <RetailSettingsStepUpdate step={step} config={config} viewId={id} />,
}

const ViewsUpdateDialog = ({ id }: { id: string }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { openUpdate, config } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)
    const _closeViewUpdator = useCallback(
        () => dispatch(closeViewUpdator()),
        [dispatch]
    )

    const _updateView = useCallback(
        async (viewId: string) => await dispatch(updateView(viewId)),
        [dispatch]
    )
    const [activeStep, setActiveStep] = useState<number>(0)

    const handleClose = () => {
        _closeViewUpdator()
    }

    const handleReset = () => {
        _closeViewUpdator()
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleSubmit = () => {
        _updateView(id)
        navigate(`/${env.type}/${env.name}/vues/${id}`)
        handleClose()
        setActiveStep(0)
    }

    const StepComponent = stepsSync[config.steps[activeStep]]

    return (
        <Dialog
            open={openUpdate}
            fullWidth={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title="Que souhaitez-vous observer ?"
                setOpen={handleClose}
            />
            {StepComponent(
                activeStep,
                {
                    handleNext,
                    handlePrevious,
                    handleReset,
                    handleSubmit,
                    stepsNumber: config.steps.length,
                },
                id
            )}
        </Dialog>
    )
}

export default ViewsUpdateDialog
