import React, { useEffect, useState } from 'react'
import { Grid, Tooltip } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import FilterSelect from '../FilterSelect'
import {
    ActiveFsCrossFilters,
    type ActiveFsFilters,
} from '../../../../redux/filters/filtersTypes'
import { EnvType } from '../../../../redux/user/userTypes'
import { LIGHT_BLUE } from '../../../ui/Colors'
import { FiltersItem, MenuType, getFiltersConfig } from '../utils'
import { useAppSelector } from '../../../../redux/hooks'

// ICONS
import InventoryIcon from '@mui/icons-material/Inventory'
import trashIcon from '../../../../assets/icon/trashIcon.svg'
import { PagesName } from '../../navigation/types/pages'

export interface FormValuesFSCrossFiltersForm {
    distributors: string[]
    products: string[]
    regions: string[]
    clients: string[]
}

interface FormProps {
    initialValues: ActiveFsCrossFilters
    onSubmit: (values: FormValuesFSCrossFiltersForm) => void
    resetMethod: (values: FormValuesFSCrossFiltersForm) => void
    filtersConfig: FiltersItem[]
    page?: PagesName
}

const PureFiltersForm = ({
    setFieldValue,
    submitForm,
    filtersConfig,
    page,
    ...props
}: FormikProps<FormValuesFSCrossFiltersForm> & FormProps) => {
    const isFiltered = useAppSelector((state) => state.global.isFiltered)

    const submitFilters = () => {
        const submit = async () => {
            const errors = await props.validateForm()

            if (Object.keys(errors).length === 0) {
                submitForm()
            }
        }

        if (props.dirty || Object.keys(props.touched).length > 0) {
            submit()
        }
    }

    const handleClearButton = () => {
        if (isFiltered) {
            props.resetForm()
            props.resetMethod(props.initialValues)
        }
    }

    return (
        <Form style={{ width: '100%' }}>
            {props.initialValues && (
                <Grid container alignItems="center" wrap="nowrap">
                    {filtersConfig.map((filter) => (
                        <FilterSelect
                            submitFilters={submitFilters}
                            name={filter.name}
                            icon={filter.icon}
                            placeholder={filter.placeholder}
                            type={filter.type}
                            values={props.values[filter.name]}
                            setFieldValue={setFieldValue}
                            key={filter.name}
                            env={EnvType.FOOD_SERVICE_CROSS}
                            page={page}
                        />
                    ))}
                    <Grid
                        container
                        alignItems="center"
                        sx={{
                            borderRadius: '50px',
                            height: '2vw',
                            width: '2vw',
                            '&:hover': {
                                background: LIGHT_BLUE,
                            },
                        }}
                    >
                        <Tooltip title="Réinitialiser les filtres">
                            <img
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vh',
                                    cursor: 'pointer',
                                }}
                                src={trashIcon}
                                onClick={() => {
                                    handleClearButton()
                                }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

const ControlledFiltersForm = withFormik<
    FormProps,
    FormValuesFSCrossFiltersForm
>({
    mapPropsToValues: (props) => ({
        distributors: props.initialValues.distributors,
        products: props.initialValues.products,
        clients: props.initialValues.clients,
        regions: props.initialValues.regions,
    }),
    enableReinitialize: true,
    handleSubmit: (
        values: FormValuesFSCrossFiltersForm,
        bag: FormikBag<FormProps, FormValuesFSCrossFiltersForm>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureFiltersForm)

const FsCrossFiltersForm = ({
    handleSubmit,
    filters,
    resetMethod,
    page,
}: {
    handleSubmit: (values: FormValuesFSCrossFiltersForm) => void
    filters: ActiveFsCrossFilters
    resetMethod: (values: FormValuesFSCrossFiltersForm) => void
    page?: PagesName
}) => {
    const { fsCrossFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)
    const [filtersConfig, setFiltersConfig] = useState<FiltersItem[]>([
        {
            id: 0,
            name: 'distributors',
            icon: <InventoryIcon />,
            placeholder: 'Distributeurs',
            type: MenuType.DISTRIBUTORS,
        },
    ])

    useEffect(() => {
        const keysFilters = fsCrossFilters ? Object.keys(fsCrossFilters) : []
        const config = getFiltersConfig(keysFilters, env.name)

        setFiltersConfig(config)
    }, [fsCrossFilters])

    return (
        <ControlledFiltersForm
            onSubmit={handleSubmit}
            initialValues={filters}
            resetMethod={resetMethod}
            filtersConfig={filtersConfig}
            page={page}
        />
    )
}

export default FsCrossFiltersForm
