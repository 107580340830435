import React from "react"
import { BLACK } from "../../../ui/Colors";
import { PrimaryText } from "../../../ui/Text";
import { Step } from "react-joyride";

export const storesPageSteps = [{
    content: <PrimaryText color={BLACK}><span style={{ fontWeight: "bold" }}>Vous êtes sur la page Points de vente</span><br /> Cette page vous permet d'obtenir plus d'informations sur vos performances en point de vente. Les produits référencés, ceux qui ne le sont plus... Détecter les risques de déréférencement, etc...</PrimaryText>,
    locale: { skip: <strong aria-label="skip">Passer</strong>, next: "Suivant", last: "Suivant", back: "Retour" },
    disableOverlayClose: true,
    placement: 'center',
    target: 'body',
    data: {},
}, {
    content: <PrimaryText color={BLACK}>Vous pouvez cliquer sur chacune des ligne pour ouvrir une carte zoom et avoir une maille d'information plus précise par point de vente !</PrimaryText>,
    locale: { next: "Suivant", last: "Suivant", back: "Retour" },
    disableOverlayClose: true,
    placement: 'bottom',
    target: ".MuiDataGrid-row",
    data: {
        next: "/retail/Carrefour/dashboard"
    },
},
] as Step[]